import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Pharmacies = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);
  const [stateOptions, setStateOptions] = useState();

  let dataTableRef = useRef(null);
  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Pharmacies");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
    setStateData();
  }, []);


  const setStateData = async () => {
    let arr = [];
    if (localStorage.StatesOptions) {
      arr = await JSON.parse(localStorage.StatesOptions);
    } else {
      let a = await fetchMethodRequest('GET', apiCalls.Settings).then(res => {
        if (res.settings[0]?.states?.length) {
          arr = res.settings[0]?.states.map(option => { return { label: option.split('-')[1].trim(), value: option, displayLabel: option } })
        }
      })
    }
    setStateOptions(arr);
  }

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },

      {
        name: "storeName",
        type: "text",
        placeholder: "Store Name",
        label: "Store Name",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Store Name",
        derivedValue: "storeName=storeName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "storeName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "storeName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "phone",
        type: "number",
        placeholder: "Phone",
        label: "Phone",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Phone",
        derivedValue: "phone=phone=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "phone",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "phone",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "fax",
        type: "number",
        placeholder: "Fax",
        label: "Fax",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Fax",
        derivedValue: "fax=fax=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "fax",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "fax",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address",
        type: "text",
        placeholder: "Address",
        label: "Address",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Address",
        derivedValue: "address=address=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "address",
        style: {
          maxWidth: '300px',
          display: 'block',
          overflow: 'hidden',
          whiteSpace: 'nowrap', /* Correct property to prevent wrapping */
          textOverflow: 'ellipsis',
        },
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address2",
        type: "text",
        placeholder: "Address2",
        label: "Address2",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Address2",
        derivedValue: "address2=address2=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "address2",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "address2",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        style: {
          maxWidth: '300px',
          display: 'block',
          overflow: 'hidden',
          whiteSpace: 'nowrap', /* Correct property to prevent wrapping */
          textOverflow: 'ellipsis',
        }
      },
      {
        name: "city",
        type: "text",
        placeholder: "City",
        label: "City",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "City",
        derivedValue: "city=city=city=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "city",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "state",
        type: "text",
        placeholder: "State",
        label: "State",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "State",
        derivedValue: "state=state=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "state",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "state",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "zipCode",
        type: "text",
        placeholder: "Zip Code",
        label: "Zip Code",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "Zip Code",
        derivedValue: "zipCode=zipCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "zipCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "zipCode",
        showOrHideFields: [],
        fieldName: "zipCode",
        fieldType: "number",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "note",
        type: "text",
        placeholder: "Note",
        label: "Note",
        width: "130px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Note",
        derivedValue: "note=note=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "note",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "note",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        style: {
          maxWidth: '300px',
          display: 'block',
          overflow: 'hidden',
          whiteSpace: 'nowrap', /* Correct property to prevent wrapping */
          textOverflow: 'ellipsis',
        }
      },
    ];
    data.push({
      "show": true,
      "textAlign": "center",
      "width": 60,
      "fieldType": "Actions",
      "field": "Actions",
      "header": "Actions",
      "filter": false,
      "sortable": false,
      displayInSettings: true,
    });
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "storeName",
        type: "text",
        placeholder: "Store Name",
        label: "Store Name",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "storeName=storeName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "storeName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "phone",
        type: "number",
        placeholder: "Phone",
        label: "Phone",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "phone=phone=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "phone",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        fieldType: 'phone',
        maxLength: config.phoneNumberLength,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "fax",
        type: "number",
        placeholder: "Fax",
        label: "Fax",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "fax=fax=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "fax",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "address",
        type: "text",
        placeholder: "Address",
        label: "Address",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "address=address=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "address2",
        type: "text",
        placeholder: "Address2",
        label: "Address2",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "address2=address2=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "address2",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "city",
        type: "text",
        placeholder: "City",
        label: "City",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        derivedValue: "city=city=city=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        customWidthClass: 'col-lg-2 col-md-2',
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "state",
        type: "dropDown",
        placeholder: "State",
        label: "State",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "state=state=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "state",
        customWidthClass: 'col-lg-1 col-md-2',
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: stateOptions,
        filter: true,
        itemTemplate: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "zipCode",
        type: "number",
        placeholder: "ZIP Code",
        label: "ZIP Code",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 13,
        derivedValue: "zipCode=zipCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "zipCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        customWidthClass: 'col-lg-1 col-md-2',
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "zipCode",
        fieldType: "number",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "note",
        type: "text",
        placeholder: "Note",
        label: "Note",
        width: "130px",
        addFormOrder: 14,
        editFormOrder: 14,
        derivedValue: "note=note=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "note",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "name",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "storeName",
        type: "text",
        placeholder: "Store Name",
        label: "Store Name",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Store Name",
        derivedValue: "storeName=storeName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "storeName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "storeName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "phone",
        type: "number",
        placeholder: "Phone",
        label: "Phone",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Phone",
        derivedValue: "phone=phone=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "phone",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "phone",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "fax",
        type: "number",
        placeholder: "Fax",
        label: "Fax",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Fax",
        derivedValue: "fax=fax=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "fax",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "fax",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address",
        type: "text",
        placeholder: "Address",
        label: "Address",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Address",
        derivedValue: "address=address=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "address",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address2",
        type: "text",
        placeholder: "Address2",
        label: "Address2",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Address2",
        derivedValue: "address2=address2=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "address2",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "address2",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "city",
        type: "text",
        placeholder: "City",
        label: "City",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "City",
        derivedValue: "city=city=city=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "city",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "state",
        type: "profile",
        placeholder: "State",
        label: "State",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "State",
        derivedValue: "state=state=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "state",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "state",
        showOrHideFields: [],
        fieldName: "state",
        fieldType: "profile",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "zipCode",
        type: "number",
        placeholder: "ZipCode",
        label: "ZipCode",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "ZipCode",
        derivedValue: "zipCode=zipCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "zipCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "zipCode",
        showOrHideFields: [],
        fieldName: "zipCode",
        fieldType: "number",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "note",
        type: "text",
        placeholder: "Note",
        label: "Note",
        width: "130px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Note",
        derivedValue: "note=note=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "note",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "note",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.pharmacies}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  return (
    <span>
      {stateOptions &&
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={true}
          PrintRequired={isEditPermission}
          // globalSearch={'Display Name/Email'}
          // type='Pharmacies'
          // apiUrl={apiCalls.Pharmacies}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.pharmacies}
          globalSearch={"Store Name"}
          displayName="Pharmacies"
          type="Pharmacies"
          routeTo={apiCalls.pharmacies}
          displayViewOfForm="screen"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.pharmacies?.toLowerCase()}
          apiUrl={apiCalls.pharmacies}
          selectedId={params.id}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="pharmacies"
          apiUrl={apiCalls.pharmacies}
        />
      ) : null}
    </span>
  );
};

export default Pharmacies;
