import React, { useEffect, useRef } from "react";
import { InputMask } from "primereact/inputmask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoInputMask = (props) => {
    const { mask, maskPlaceholder, name, field, placeholder, focus, setValue, nextFocusedElement, handleFocus } = props;
	const id = props.id ? props.id : props.name;
	const label = props.label ? props.label : props.name;
	const item = props.item ? props.item : {};
	const inputMaskRef = useRef(null);

	useEffect(() => {
		if(focus && inputMaskRef?.current?.props?.name === focus) {
			inputMaskRef.current?.focus();
		}
	},[focus])



	let markRequired = () => {
		return (
			<FontAwesomeIcon
				color="red"
				icon={faAsterisk}
				style={{ width: "7px", marginBottom: "5px", marginLeft: "2px" }}
			/>
		);
	};




	return (
		<div className="flex flex-column">
			<div className="flex align-center">
				{!item.disableLabel && (
					<>
						<label htmlFor={id} className="text-capitalize">
							{label}
						</label>
						{props.required && props.required === true && markRequired()}
					</>
				)}
			</div>
			<InputMask
				focus
                slotChar={maskPlaceholder}
				value={field.value}
				ref={inputMaskRef}
				id={id}
				name={name}
				placeholder={placeholder}
				disabled={item.disabled ? true : false}
				onChange={(e) => field.onChange(e.target.value)}
                mask={mask}
				onComplete={(e) => { handleFocus(nextFocusedElement)}}
			/>
			<small className="text-danger">
				{props.fieldState.invalid ? props.errors[name]?.message : ""}
			</small>
		</div>
	);
};

export default DoInputMask;
