import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Referrals = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);
  const [stateOptions, setStateOptions] = useState();
  const [clinicOptions, setClinicOptions] = useState([]);

  const currentValuesfalse = false;

  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Referrals");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
    setStateData();
    setClinicData();
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  // get States 
  const setStateData = async () => {
    let arr = [];
    if (localStorage.StatesOptions) {
      arr = await JSON.parse(localStorage.StatesOptions);
    } else {
      let a = await fetchMethodRequest('GET', apiCalls.Settings).then(res => {
        if (res.settings[0]?.states?.length) {
          arr = res.settings[0]?.states.map(option => { return { label: option.split('-')[1].trim(), value: option, displayLabel: option } })
        }
      })
    }
    setStateOptions(arr);
  }

  //clinic data
  const setClinicData = () => {
    fetchMethodRequest('GET', apiCalls.clinics).then(res => {
      let arr = [];
      if (res && res[apiCalls.clinics]) {
        arr = res[apiCalls.clinics].map(c => ({ label: c.fullClinicName, value: c._id }))
      }
      setClinicOptions(arr);
    })
  }



  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },

      {
        name: "firstName",
        fieldName: "firstName",
        type: "text",
        placeholder: "First Name",
        value: "",
        label: "First Name",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "First Name",
        derivedValue: "firstName=undefined",
        capitalizeTableText: true,
        sortable: false,
        filter: true,
        id: "firstName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "firstName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "lastName",
        fieldName: "lastName",
        type: "text",
        placeholder: "Last Name",
        value: "",
        label: "Last Name",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Last Name",
        derivedValue: "lastName=undefined",
        capitalizeTableText: true,
        sortable: false,
        filter: true,
        id: "lastName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "lastName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },

      {
        name: "title",
        fieldName: "title",
        type: "text",
        placeholder: "Title",
        value: "",
        label: "Title",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Title",
        derivedValue: "title=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "title",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "title",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address",
        fieldName: "address",
        type: "text",
        placeholder: "Address",
        value: "",
        label: "Address",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Address",
        derivedValue: "address=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "address",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phone",
        fieldName: "phone",
        type: "text",
        placeholder: "Phone",
        value: "",
        label: "Phone",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Phone",
        derivedValue: "phone=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "phone",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "phone",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },

      {
        name: "email",
        fieldName: "email",
        type: "email",
        placeholder: "Email",
        value: "",
        label: "Email",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Email",
        derivedValue: "email=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },

      {
        name: "speciality",
        fieldName: "speciality",
        type: "dropDown",
        placeholder: "Speciality",
        value: "",
        label: "Speciality",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Speciality",
        derivedValue: "speciality=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "speciality",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        options: [
          {
            label: "None",
            value: "None",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "General",
            value: "General",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Hygienist",
            value: "Hygienist",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Endodontics",
            value: "Endodontics",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Pediatric",
            value: "Pediatric",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Perio",
            value: "Perio",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Prosth",
            value: "Prosth",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Ortho",
            value: "Ortho",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Denturist",
            value: "Denturist",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Surgery",
            value: "Surgery",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Assistant",
            value: "Assistant",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "LabTech",
            value: "LabTech",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Pathology",
            value: "Pathology",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "PublicHealth",
            value: "PublicHealth",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Radiology",
            value: "Radiology",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "None",
            value: "None",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "General",
            value: "General",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Hygienist",
            value: "Hygienist",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Endodontics",
            value: "Endodontics",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Pediatric",
            value: "Pediatric",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Perio",
            value: "Perio",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Prosth",
            value: "Prosth",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Ortho",
            value: "Ortho",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Denturist",
            value: "Denturist",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Surgery",
            value: "Surgery",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Assistant",
            value: "Assistant",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "LabTech",
            value: "LabTech",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Pathology",
            value: "Pathology",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "PublicHealth",
            value: "PublicHealth",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Radiology",
            value: "Radiology",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "speciality",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },

      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  /**@TitleOptions */
  const titleOptions = [
    { label: "Mr", value: "Mr", color: "primary" },
    { label: "Mrs", value: "Mrs", color: "primary" },
    { label: "Miss", value: "Miss", color: "primary" },
    { label: "Ms", value: "Ms", color: "primary" },
  ];

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: 1001,
        fieldType: "Text",
        show: true,
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        show: true,
        filter: true,
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        disabled: true,
        show: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        disabled: true,
        show: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "firstName",
        fieldName: "firstName",
        type: "text",
        placeholder: "First Name",
        value: "",
        label: "First Name",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 1,
        editFormOrder: 1,
        stringType: null,
        derivedValue: "firstName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "firstName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        isMultipleRequired: "",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "lastName",
        fieldName: "lastName",
        type: "text",
        placeholder: "Last Name",
        value: "",
        label: "Last Name",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 2,
        editFormOrder: 2,
        stringType: null,
        derivedValue: "lastName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "lastName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        isMultipleRequired: "",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "middleName",
        fieldName: "middleName",
        type: "text",
        placeholder: "Middle Name",
        value: "",
        label: "Middle Name",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 3,
        editFormOrder: 3,
        stringType: null,
        derivedValue: "middleName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "middleName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "title",
        type: "dropDown",
        placeholder: "Title",
        label: "Title",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 4,
        editFormOrder: 4,
        stringType: null,
        derivedValue: "title=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "title",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: titleOptions,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "address",
        fieldName: "address",
        type: "text",
        placeholder: "Address",
        value: "",
        label: "Address",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 5,
        editFormOrder: 5,
        stringType: null,
        derivedValue: "address=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "address2",
        fieldName: "address2",
        type: "text",
        placeholder: "Address2",
        value: "",
        label: "Address2",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 6,
        editFormOrder: 6,
        stringType: null,
        derivedValue: "address2=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "address2",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "city",
        type: "text",
        placeholder: "City",
        label: "City",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "city=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        customWidthClass: 'col-lg-2 col-md-2',
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "state",
        type: "dropDown",
        placeholder: "State",
        label: "State",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "state=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "state",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: stateOptions,
        itemTemplate: true,
        filter: true,
        customWidthClass: 'col-lg-1 col-md-2',
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "zipCode",
        type: "number",
        placeholder: "ZIP Code",
        label: "ZIP Code",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "zipCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "zipCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        customWidthClass: 'col-lg-1 col-md-2',
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "clinicsId",
        type: "dropDown",
        placeholder: "Select Clinics",
        label: "Clinics ID",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "clinicsId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "clinicsId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        options: clinicOptions,
        controllerId: 1011,
        isMultiSelect: true,
        fieldType: 'dropDown',
        filter: true,
        show: true,
        showOrHideFields: [],
        fieldName: "clinicsId",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: currentValuesfalse,
      },
      {
        name: "phone",
        fieldName: "phone",
        type: "text",
        placeholder: "Phone",
        value: "",
        label: "Phone",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 11,
        editFormOrder: 11,
        stringType: null,
        derivedValue: "phone=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "phone",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "otherPhone",
        fieldName: "otherPhone",
        type: "text",
        placeholder: "Other Phone",
        value: "",
        label: "Other Phone",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 12,
        editFormOrder: 12,
        stringType: null,
        derivedValue: "otherPhone=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "otherPhone",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "email",
        fieldName: "email",
        type: "email",
        placeholder: "Email",
        value: "",
        label: "Email",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 13,
        editFormOrder: 13,
        stringType: null,
        derivedValue: "email=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "businessName",
        fieldName: "businessName",
        type: "text",
        placeholder: "Business Name",
        value: "",
        label: "Business Name",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 14,
        editFormOrder: 14,
        stringType: null,
        derivedValue: "businessName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "businessName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "nationalProviderId",
        fieldName: "nationalProviderId",
        type: "text",
        placeholder: "National Provider ID",
        value: "",
        label: "National Provider ID",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 15,
        editFormOrder: 15,
        stringType: null,
        derivedValue: "nationalProviderId=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "nationalProviderId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },


      {
        name: "speciality",
        fieldName: "speciality",
        type: "dropDown",
        placeholder: "Speciality",
        value: "",
        label: "Speciality",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 16,
        editFormOrder: 16,
        stringType: null,
        derivedValue: "speciality=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "speciality",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        isMultipleRequired: "",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        options: [
          {
            label: "None",
            value: "None",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "General",
            value: "General",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Hygienist",
            value: "Hygienist",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Endodontics",
            value: "Endodontics",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Pediatric",
            value: "Pediatric",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Perio",
            value: "Perio",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Prosth",
            value: "Prosth",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Ortho",
            value: "Ortho",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Denturist",
            value: "Denturist",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Surgery",
            value: "Surgery",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Assistant",
            value: "Assistant",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "LabTech",
            value: "LabTech",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Pathology",
            value: "Pathology",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "PublicHealth",
            value: "PublicHealth",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Radiology",
            value: "Radiology",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "None",
            value: "None",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "General",
            value: "General",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Hygienist",
            value: "Hygienist",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Endodontics",
            value: "Endodontics",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Pediatric",
            value: "Pediatric",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Perio",
            value: "Perio",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Prosth",
            value: "Prosth",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Ortho",
            value: "Ortho",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Denturist",
            value: "Denturist",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Surgery",
            value: "Surgery",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Assistant",
            value: "Assistant",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "LabTech",
            value: "LabTech",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Pathology",
            value: "Pathology",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "PublicHealth",
            value: "PublicHealth",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Radiology",
            value: "Radiology",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },

      {
        name: "notes",
        fieldName: "notes",
        type: "textarea",
        placeholder: "Notes",
        value: "",
        label: "Notes",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "notes=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "notes",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "firstName",
        fieldName: "firstName",
        type: "text",
        placeholder: "First Name",
        value: "",
        label: "First Name",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "First Name",
        derivedValue: "firstName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "firstName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "firstName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "lastName",
        fieldName: "lastName",
        type: "text",
        placeholder: "Last Name",
        value: "",
        label: "Last Name",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Last Name",
        derivedValue: "lastName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "lastName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "lastName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "middleName",
        fieldName: "middleName",
        type: "text",
        placeholder: "Middle Name",
        value: "",
        label: "Middle Name",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Middle Name",
        derivedValue: "middleName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "middleName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "middleName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "title",
        fieldName: "title",
        type: "text",
        placeholder: "Title",
        value: "",
        label: "Title",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Title",
        derivedValue: "title=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "title",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "title",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address",
        fieldName: "address",
        type: "text",
        placeholder: "Address",
        value: "",
        label: "Address",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Address",
        derivedValue: "address=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "address",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address2",
        fieldName: "address2",
        type: "text",
        placeholder: "Address2",
        value: "",
        label: "Address2",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Address2",
        derivedValue: "address2=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "address2",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "address2",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "city",
        fieldName: "city",
        type: "text",
        placeholder: "City",
        value: "",
        label: "City",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "City",
        derivedValue: "city=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "city",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "state",
        fieldName: "state",
        type: "text",
        placeholder: "State",
        value: "",
        label: "State",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "State",
        derivedValue: "state=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "state",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "state",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "zipCode",
        fieldName: "zipCode",
        type: "text",
        placeholder: "Zip Code",
        value: "",
        label: "Zip Code",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Zip Code",
        derivedValue: "zipCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "zipCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "zipCode",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phone",
        fieldName: "phone",
        type: "text",
        placeholder: "Phone",
        value: "",
        label: "Phone",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Phone",
        derivedValue: "phone=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "phone",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "phone",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "otherPhone",
        fieldName: "otherPhone",
        type: "text",
        placeholder: "Other Phone",
        value: "",
        label: "Other Phone",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Other Phone",
        derivedValue: "otherPhone=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "otherPhone",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "otherPhone",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "email",
        fieldName: "email",
        type: "email",
        placeholder: "Email",
        value: "",
        label: "Email",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Email",
        derivedValue: "email=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "businessName",
        fieldName: "businessName",
        type: "text",
        placeholder: "Business Name",
        value: "",
        label: "Business Name",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Business Name",
        derivedValue: "businessName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "businessName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "businessName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "nationalProviderId",
        fieldName: "nationalProviderId",
        type: "text",
        placeholder: "National Provider ID",
        value: "",
        label: "National Provider ID",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "National Provider ID",
        derivedValue: "nationalProviderId=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "nationalProviderId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "nationalProviderId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "preferredReferral",
        fieldName: "preferredReferral",
        type: "text",
        placeholder: "Preferred Referral",
        value: "",
        label: "Preferred Referral",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Preferred Referral",
        derivedValue: "preferredReferral=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "preferredReferral",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "preferredReferral",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "isDoctor",
        fieldName: "isDoctor",
        type: "checkbox",
        placeholder: "Is Doctor",
        value: "",
        label: "Is Doctor",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Is Doctor",
        derivedValue: "isDoctor=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "isDoctor",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        textAlign: "center",
        show: true,
        field: "isDoctor",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "speciality",
        fieldName: "speciality",
        type: "dropDown",
        placeholder: "Speciality",
        value: "",
        label: "Speciality",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Speciality",
        derivedValue: "speciality=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "speciality",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        options: [
          {
            label: "None",
            value: "None",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "General",
            value: "General",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Hygienist",
            value: "Hygienist",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Endodontics",
            value: "Endodontics",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Pediatric",
            value: "Pediatric",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Perio",
            value: "Perio",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Prosth",
            value: "Prosth",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Ortho",
            value: "Ortho",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Denturist",
            value: "Denturist",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Surgery",
            value: "Surgery",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Assistant",
            value: "Assistant",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "LabTech",
            value: "LabTech",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Pathology",
            value: "Pathology",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "PublicHealth",
            value: "PublicHealth",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Radiology",
            value: "Radiology",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "None",
            value: "None",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "General",
            value: "General",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Hygienist",
            value: "Hygienist",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Endodontics",
            value: "Endodontics",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Pediatric",
            value: "Pediatric",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Perio",
            value: "Perio",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Prosth",
            value: "Prosth",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Ortho",
            value: "Ortho",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Denturist",
            value: "Denturist",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Surgery",
            value: "Surgery",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Assistant",
            value: "Assistant",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "LabTech",
            value: "LabTech",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Pathology",
            value: "Pathology",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "PublicHealth",
            value: "PublicHealth",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Radiology",
            value: "Radiology",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "speciality",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "referralSlip",
        fieldName: "referralSlip",
        type: "dropDown",
        placeholder: "Referral Slip",
        value: "",
        label: "Referral Slip",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Referral Slip",
        derivedValue: "referralSlip=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "referralSlip",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        options: [
          {
            label: "Default",
            value: "Default",
            color: "",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "Default",
            value: "Default",
            color: "",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "referralSlip",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "notes",
        fieldName: "notes",
        type: "textarea",
        placeholder: "Notes",
        value: "",
        label: "Notes",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Notes",
        derivedValue: "notes=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "notes",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "notes",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();

    // if (type == "submit") {
    // type == "submit"
    //   ?
    // await this.dataTableRef.getDataFromServer(this.state.filterCriteria, "refresh");
    // }

    // : null;
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.referrals}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={true}
          // globalSearch={'Display Name/Email'}
          // type='Referrals'
          // apiUrl={apiCalls.Referrals}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.referrals}
          globalSearch={
            "FirstName/LastName/Speciality"}
          displayName="Referrals"
          type="Referrals"
          routeTo={apiCalls.referrals}
          displayViewOfForm="screen"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.referrals?.toLowerCase()}
          apiUrl={apiCalls.referrals}
          selectedId={params.id}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="referrals"
          apiUrl={apiCalls.referrals}
        />
      ) : null}
    </span>
  );
};

export default Referrals;
