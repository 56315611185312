import React from 'react';
import { Button, Modal,ModalBody } from 'reactstrap';


// config file
const confirmationModal = (props) => {

    let displayText = props.type === "Insurance" ? `Are You  Subscriber`   : `Are you sure you want to` 

    return (
        <div>
            <Modal isOpen={props.openConfirmationModal} className='deleteModalHeight'>
                <ModalBody className='deleteModalBody'>
                    <div style={{ marginBottom: 10 }}> {displayText} {props.text}?</div>
                    <Button color="primary" outline onClick={props.closeConfirmationModal} className='deleteModalBtn marginRight'>No</Button>
                    <Button color="primary" outline onClick={props.confirm}
                        className='deleteModalBtn'>Yes</Button>
                </ModalBody>
            </Modal>
        </div>
    );

}
export default confirmationModal;