import React, { useState, useEffect, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar as PrimeCalendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { ListBox } from 'primereact/listbox';
import { Button } from 'primereact/button';
import { Calendar } from 'react-big-calendar';
import fetchMethodRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls';
import DoAutoCompleteField from '../../Form/Fields/DoAutoCompleteField';
import CustomTimePicker from '../../Form/Fields/CustomTimePicker';
import { AutoComplete } from "primereact/autocomplete";
import { GlobalContext } from '../../App/App';
import moment from 'moment';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UpdateSlotModal = ({ eventDate, startTime, endTime, control, getValues }) => {

  const { handleSubmit, formState: { errors } } = useForm();

  const [value, setValue] = useState(null);

  const [items, setItems] = useState([]);
  const context = useContext(GlobalContext);

  const search = (event) => {
    const storedClinicData = context?.clinicData;
    if (!storedClinicData) {
      return;
    }
    const requestUrl = `${apiCalls.clinics}/${storedClinicData._id}`;
    fetchMethodRequest('GET', requestUrl)
      .then((res) => {
        if (res && res.chairs) {
          const sortedChairs = res.chairs.sort((a, b) => {
            const numA = parseInt(a.name.replace(/\D/g, ''), 10); // Extract numbers from string 'a.name'
            const numB = parseInt(b.name.replace(/\D/g, ''), 10); // Extract numbers from string 'b.name'
            if (numA === numB) {
              return a.name.localeCompare(b.name, undefined, { numeric: true });
            }
            return numA - numB;
          });
          setItems(sortedChairs);
        }
      })
  }
  
  const markRequired = () => (
    <FontAwesomeIcon
      color="red"
      icon={faAsterisk}
      style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
    />
  );

  const handleUpdateSlotSubmit = (data) => {
    onSubmit(data);
  }

  let getAutoComplete = (i, item) => {
    let itemTemplateFunction;

    if (item.populateValue && item.populateField) {
      itemTemplateFunction = (option) => {
        const value = option[item.populateValue];
        const label = option[item.populateField];
        return (
          <div className="autocomplete-item">
            <div>{`${value} (Name : ${label})`}</div>
          </div>
        );
      };
    }
    const handleHandlerSelect = (value) => {
      setSelectedHandler(value);
    };
    return (
      <div>
        <Controller
          name={item.name}
          rules={{ required: `${item.label} is required` }}
          control={control}
          render={({ field, fieldState }) => (
            <>
              <DoAutoCompleteField
                input={field}
                id={field.id}
                name={field.name}
                field={field}
                item={item}
                filterField={item.filterField}
                filterValue={item.filterValue}
                filterType={item.isNotEq}
                multiple={item.isMultiple}
                fieldState={fieldState}
                errors={errors}
                screen={"Schedules"}
                searchApi={item.searchApi}
                itemTemplate={itemTemplateFunction}
                searchField={item.searchField}
                //   allow={props.allowDuplicates}
                filterFieldType={item.filterFieldType ? item.filterFieldType : null}
                placeholder={item.placeholder}
                label={item.label}
                setValue={setValue}
                formType={"add"}
                populateValue={item.populateValue}
                populateField={item.populateField}
              />
              {fieldState.error && (
                <p style={{ color: 'red' }}>{fieldState.error.message}</p>
              )}
            </>
          )}

        />
      </div>
    )
  }
  let minDate = new Date();

  const formatTimeFromMoment = (value) => {
    const format = "HH:mm";
    const regex = /^\d{2}:\d{2}$/;
    const formattedTime = regex.test(value) ? value : moment(value).format(format);
    return formattedTime;
  };

  /**
   * 
   * @param {String} time 
   * @returns Number
   */
  const convertIntoMin = (time) => {
    const regex = /^\d{2}:\d{2}$/;
    time = formatTimeFromMoment(time);
    if (time && regex.test(time)) {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    }
    return 0;
  }

  /**
   * 
   * @param {String} startTime 
   * @param {String} endTime 
   * @returns 
   */
  const validateTimeRange = (startTime, endTime) => {
    startTime = convertIntoMin(startTime);
    endTime = convertIntoMin(endTime);
    console.log('------------', startTime, endTime);
    if (!startTime || !endTime || startTime < endTime) {
      return true;
    }
    return false;
  }

  return (
    <div className="card">
      <div class='addSlotOperative'>
        <label htmlFor="operatory">Operatory{markRequired()}</label>
        <Controller
          name="handler"
          control={control}
          rules={{ required: 'Operatory is required' }}
          render={({ field, fieldState }) => (
            <AutoComplete
              value={field.value}
              suggestions={items}
              field="name"
              completeMethod={search}
              onChange={(e) => {
                field.onChange(e.value)
              }}
              dropdown
              placeholder='Select a Operatory'
              disabled={true}
            />
          )}
        />
        {errors.operative && <p style={{ color: 'red' }}>{errors.operative.message}</p>}
      </div>
      <div>
        <div className="calendar-form-group-date">
          <label htmlFor="eventDate">Date {markRequired()}</label>
          <Controller
            name="eventDate"
            control={control}
            rules={{ required: 'Event date range is required' }}
            render={({ field, fieldState }) => (
              <>
                <PrimeCalendar id="eventDate" className='schedule-date col-md-12' {...field} dateFormat="yy-mm-dd" minDate={minDate} showIcon />
                {fieldState.error && <p style={{color: 'red'}}>{fieldState.error.message}</p>}
              </>
            )}
          />
        </div>
        <div className="calendar-form-group-row">

          <div className="calendar-form-group calendar-form-group-time">
            <label htmlFor="startTime">Start Time {markRequired()}</label>
            <Controller
              name="startTime"
              control={control}
              rules={{ required: 'Start time is required' }}
              render={({ field, fieldState }) => (
                <>
                  <CustomTimePicker id="startTime" {...field} hourFormat="12" showIcon />
                  {fieldState.error && <p style={{ color: 'red' }}>{fieldState.error.message}</p>}
                </>
              )}
            />
          </div>

          <div className="calendar-form-group calendar-form-group-time">
            <label htmlFor="endTime">End Time {markRequired()}</label>
            <Controller
              name="endTime"
              control={control}
              rules={{
                required: 'End time is required',
                validate: value => validateTimeRange(getValues && getValues('startTime'), value) || 'To time must be greater than the from time.',
              }}
              render={({ field, fieldState }) => (
                <>
                  <CustomTimePicker id="endTime" {...field} hourFormat="12" showIcon />
                  {fieldState.error && <p style={{ color: 'red' }}>{fieldState.error.message}</p>}
                </>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateSlotModal;