import React from "react";
import { InputNumber } from 'primereact/inputnumber';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoDecimalField = (props) => {
    const id = props.item?.id ? props.item.id : props.name;
    const label = props.item?.label ? props.item.label : props.name;
    const name = props.name;
    const type = props.type ? props.type : 'text';
    let field = props.field;
    const placeholder = props.item?.placeholder;
    const item = props.item ? props.item : {};

    const onChange = (e) => {
        field.onChange(e.value);
        if (props.onChange) {
            props.onChange(e.value);
        }
    }

    const handleBlur = (e) => {
        if(props.handleBlur){
          props.handleBlur(e.target.ariaValueNow)
        }
    };

    return (
        <div className="flex flex-column">
            <label htmlFor={id} className="text-capitalize">{label}
                {item.required && <FontAwesomeIcon
                    color='red'
                    icon={faAsterisk}
                    style={{ width: '7px', marginLeft: "2px" }}
                />}
            </label>
            <InputNumber
                placeholder={placeholder}
                value={field.value}
                onChange={onChange}
                mode="decimal"
                minFractionDigits={0}
                maxFractionDigits={2}
                min={0}
                onBlur = {handleBlur}
            />

            <small className="text-danger ">{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>

        </div>
    )

}

export default DoDecimalField;