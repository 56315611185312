import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button as ReactStrapButton } from 'reactstrap';
import { Space, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import moment from 'moment';
import config from '../../../../../config/config';
import apiCalls from '../../../../../config/apiCalls';
import DoSelectField from '../../../../Form/Fields/DoSelectField';
import DoMultiSelectField from '../../../../Form/Fields/DoMultiSelectField';
import fetchMethodRequest from '../../../../../config/service';
import showToasterMessage from '../../../../UI/ToasterMessage/toasterMessage';

function StatementPdf({ visible, onHide, treatments, accounts, totalAmount }) {
  const { RangePicker } = DatePicker;
  const { control, handleSubmit, formState: { errors } } = useForm();
  const [pdfUrl, setPdfUrl] = useState('');
  const [showEmailButton, setShowEmailButton] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [transactionType, setTaransactionType] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const TransactionTypeOptions = [
    { label: 'Adjustment', value: 'Adjustment' },
    { label: 'Claim Payment', value: 'Claim Payment' },
    { label: 'Payment', value: 'Payment' },
    { label: 'Procedure', value: 'Procedure' },
  ];
  // Render multi-select dropdown field
  let getDropdownMultiselect = (i, item) => {
    const onSelectMultiSelectValue = (value) => {
      setTaransactionType(value);
    }
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoMultiSelectField
              input={field}
              id={field.id}
              item={item}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              label={item.label}
              options={item.options}
              optionLabel={item.optionLabel}
              placeholder={item.placeholder}
              onChange={onSelectMultiSelectValue}
            />)}
        />
      </div>
    )
  }

  // Preset options for date range picker
  const rangePresets = [
    {
      label: 'Previous 30 Days',
      value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
      label: 'Previous 90 Days',
      value: [dayjs().add(-90, 'd'), dayjs()],
    },
    {
      label: 'Previous 180 Days',
      value: [dayjs().add(-180, 'd'), dayjs()],
    },
    {
      label: 'Previous 1 year',
      value: [dayjs().add(-365, 'd'), dayjs()],
    },
  ];

  // Render date range picker
  const getRangePicker = (i, item) => (

    <div key={i}>
      <Space direction="vertical" size={12}>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <>
              <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '20px' }}>
                <label>Date Range</label>
                <RangePicker
                  appendTo={document.body}
                  onChange={(dates, dateStrings) => {
                    const formattedDates = dateStrings.map(date => dayjs(date).format('YYYY-MM-DD'));
                    field.onChange(formattedDates);
                    setDateRange(formattedDates);
                  }}
                  format="MM-DD-YYYY"
                  disabledDate={(current) => current && current > dayjs().endOf('day')}
                  presets={[
                    {
                      label: (
                        <span aria-label="Current Time to End of Day">Today</span>
                      ),
                      value: () => [dayjs(), dayjs().endOf("day")],
                    },
                    ...rangePresets,
                  ]}
                />
              </div>
            </>
          )}
        />
      </Space>
    </div>
  );

  // Handle statement download
  const downloadStatements = async () => {
    const obj = treatments[0];
    let hospitalData = localStorage.getItem('HospitalData') ? JSON.parse(localStorage.getItem('HospitalData')) : " ";
    let clinicData = localStorage.getItem('ClinicData') ? JSON.parse(localStorage.getItem('ClinicData')) : " ";
    const payload = {
      total: accounts[0]?.totalAmount,
      patientId: obj.patientId,
      accountId: obj.accountId,
      statement: treatments,
      date: moment().format(config.dateFormat),
      transactionType: transactionType,
      dateRange: dateRange,
      hospitalData : hospitalData,
      clinicData : clinicData,
    };
    
    try {
      const response = await axios.post(`${config.apiUrl}${apiCalls.accountsStatement}`, payload, {
        responseType: 'blob',
      });

      // Create a URL for the PDF blob
      const pdfBlobUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

      // Set the PDF URL to display in the iframe
      setPdfUrl(pdfBlobUrl);
      setShowEmailButton(true);
      setIsFormSubmitted(true);
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  }
  // Handle sending email
  const handleEmail = async () => {
    let hospitalData = localStorage.getItem('HospitalData') ? JSON.parse(localStorage.getItem('HospitalData')) : " ";
    let patientData = localStorage.getItem('PatientData') ? JSON.parse(localStorage.getItem('PatientData')) : " ";

    let payload = {
      hospitalId: hospitalData,
      patientId: patientData,
      totalAmount: totalAmount
    };

    try {
      const response = await fetchMethodRequest('POST', apiCalls.sendEmailtoPatient, payload);

      if (response.respMessage) {
        showToasterMessage('Email sent successfully', 'success');
      } else {
        showToasterMessage('Failed to send email', 'error');
      }
    } catch (error) {
      console.error('Error Sending Email:', error);
      showToasterMessage('Error sending email', 'danger');
    } finally {
      onHide();
    }
  };
  
  // Dialog width depends on form submission status
  const dialogWidth = isFormSubmitted ? '60vw' : '20vw';

  return (
    <Dialog
      visible={visible}
      style={{ width: dialogWidth }}
      header="Statement PDF"
      onHide={onHide}
      modal
    >
      <form onSubmit={handleSubmit(downloadStatements)} style={{ paddingTop: '30px' }} >
        {!isFormSubmitted && (
          <>
            <div>
              <div>
                {getRangePicker(2, { name: 'dateRange', label: 'Date Range', type: 'dateRangePicker', show: true, displayForm: ['statement'], placeholder: 'Select Date Range' })}
              </div>
              <div style={{ width: '91%', paddingBottom: '30px' }}>
                {getDropdownMultiselect(1, {
                  name: "transactionTypes",
                  placeholder: "Select Transaction Types",
                  label: "Select Transaction Types",
                  options: TransactionTypeOptions,
                  optionLabel: 'label',
                  isMultiple: true,
                  show: true,
                })}
              </div>
            </div>
            <div style={{ paddingTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
              <ReactStrapButton type="submit" color="primary">Submit</ReactStrapButton>
              <ReactStrapButton color="primary" onClick={onHide}>Cancel</ReactStrapButton>
            </div>
          </>
        )}
      </form>

      {pdfUrl && (
        <div style={{ marginTop: '20px' }}>
          <iframe
            src={pdfUrl}
            style={{ width: '100%', height: '70vh', border: 'none' }}
            title="PDF Viewer"
          />
          {showEmailButton && (
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              <ReactStrapButton color="primary" onClick={handleEmail}>Send Email</ReactStrapButton>
            </div>
          )}
        </div>
      )}
    </Dialog>
  );
}

export default StatementPdf;
