import config from "./config";
const commonSampleFilePath = "http://localhost:3000/images/sample";

const configSampleFiles = {
  employees:
    `${config.sampleFileUrl}sample_employees_bulk_upload_file.csv`,
  activities:
    `${config.sampleFileUrl}sample_activities_bulk_upload_file.csv`,
  emailtemplates:
    `${config.sampleFileUrl}sample_emailtemplates_bulk_upload_file.csv`,
  roles:
    `${config.sampleFileUrl}sample_roles_bulk_upload_file.csv`,
  uploadhistories:
    `${config.sampleFileUrl}sample_uploadhistories_bulk_upload_file.csv`,
  emailstatuses:
    `${config.sampleFileUrl}sample_emailstatuses_bulk_upload_file.csv`,
  users:
    `${config.sampleFileUrl}sample_users_bulk_upload_file.xlsx`,
  hospitals:
    `${config.sampleFileUrl}sample_hospitals_bulk_upload_file.csv`,
  clinics:
    `${config.sampleFileUrl}sample_clinics_bulk_upload_file.csv`,
  patients:
    `${config.sampleFileUrl}sample_patients_bulk_upload_file.xlsx`,
  templates:
    `${config.sampleFileUrl}sample_templates_bulk_upload_file.csv`,
  forms:
    `${config.sampleFileUrl}sample_forms_bulk_upload_file.csv`,
  schedules:
    `${config.sampleFileUrl}sample_schedules_bulk_upload_file.csv`,
  chairs:
    `${config.sampleFileUrl}sample_chairs_bulk_upload_file.csv`,
  appointments:
    `${config.sampleFileUrl}sample_appointments_bulk_upload_file.csv`,
  procedures:
    `${config.sampleFileUrl}sample_Procedures_bulk_upload_file.xlsx`,
  txplans:
    `${config.sampleFileUrl}sample_txplans_bulk_upload_file.csv`,
  treatments:
    `${config.sampleFileUrl}sample_treatments_bulk_upload_file.csv`,
  prescriptions:
    `${config.sampleFileUrl}sample_prescriptions_bulk_upload_file.csv`,
  pharmacies:
    `${config.sampleFileUrl}sample_pharmacies_bulk_upload_file.xlsx`,
  treatmentplans:
    `${config.sampleFileUrl}sample_treatmentplans_bulk_upload_file.csv`,
  drugs:
    `${config.sampleFileUrl}sample_drugs_bulk_upload_file.csv`,
  reasons:
    `${config.sampleFileUrl}sample_reasons_bulk_upload_file.csv`,
  insurances:
    `${config.sampleFileUrl}sample_insurances_bulk_upload_file.csv`,
  carriers:
    `${config.sampleFileUrl}sample_carriers_bulk_upload_file.csv`,
  benifits:
    `${config.sampleFileUrl}sample_benifits_bulk_upload_file.csv`,
  feeschedules:
    `${config.sampleFileUrl}sample_feeschedules_bulk_upload_file.xlsx`,
  patienteligibilities:
    `${config.sampleFileUrl}sample_patienteligibilities_bulk_upload_file.csv`,
  releasenotes:
    `${config.sampleFileUrl}sample_releasenotes_bulk_upload_file.csv`,
  procedureslists:
    `${config.sampleFileUrl}sample_procedureslists_bulk_upload_file.csv`,
  chartprocedures:
    `${config.sampleFileUrl}sample_chartprocedures_bulk_upload_file.csv`,
  clockInClockOut:
    `${config.sampleFileUrl}sample_clockinclockout_bulk_upload_file.csv`,
  availableprompts:
    `${config.sampleFileUrl}sample_availableprompts_bulk_upload_file.csv`,
  autonotes:
    `${config.sampleFileUrl}sample_autonotes_bulk_upload_file.csv`,
  charthistories:
    `${config.sampleFileUrl}sample_charthistories_bulk_upload_file.csv`,
  uploadteethimages:
    `${config.sampleFileUrl}sample_uploadteethimages_bulk_upload_file.csv`,
  patientnotes:
    `${config.sampleFileUrl}sample_patientnotes_bulk_upload_file.csv`,
  referrals:
    `${config.sampleFileUrl}sample_referrals_bulk_upload_file.csv`,
  accounts:
    `${config.sampleFileUrl}sample_accounts_bulk_upload_file.csv`,
  makepayments:
    `${config.sampleFileUrl}sample_makepayments_bulk_upload_file.csv`,
  imagemaintainings:
    `${config.sampleFileUrl}sample_imagemaintainings_bulk_upload_file.csv`,
  periocharts:
    `${config.sampleFileUrl}sample_periocharts_bulk_upload_file.csv`,
  payments:
    `${config.sampleFileUrl}sample_payments_bulk_upload_file.csv`,
  claimmanagements:
    `${config.sampleFileUrl}sample_claimmanagements_bulk_upload_file.csv`,
};
export default configSampleFiles;
