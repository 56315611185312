import React, { useEffect, useState } from "react";

import DoAutoCompleteField from "../../../Form/Fields/DoAutoCompleteField";
import DoMultiSelectField from "../../../Form/Fields/DoMultiSelectField";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import fetchMethodRequest from "../../../../config/service";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave, faCheck, faAsterisk, faTimes } from '@fortawesome/free-solid-svg-icons';
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';


export default function DrugInputField(props) {
    let { i, item, Controller, control, getValues, setValue, errors, selectedData, setCustomFieldValidation } = props
    const [options, setOptions] = useState();
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isOpenFormModal, setIsOpenFormModal] = useState(false);
    const [drugData, setDrugData] = useState({});
    const [formType, setFormType] = useState(window.location.href.includes("create") ? 'add' : 'edit')


    const formFields = [
        { name: 'name', header: 'Drug', type: 'text', editable: false, field: 'name', style: { width: '20%', borderLeft: '1px solid #dedede' }, className: '', placeholder: 'Drug Name' },
        { name: 'instructions', header: 'Instructions', type: 'textarea', editable: true, field: 'instructions', style: { width: '50%' }, className: '', placeholder: 'Instructions' },
        { name: 'dosage', header: 'Dosage', type: 'text', editable: true, field: 'dosage', style: { width: '9%' }, className: '', placeholder: 'Dosage' },
        { name: 'disp', header: 'Disp', type: 'number', editable: true, field: 'disp', style: { width: '8%' }, className: '', placeholder: 'Disp' },
        { name: 'refills', header: 'Refills', type: 'number', editable: true, field: 'refills', style: { width: '9%' }, className: '', placeholder: 'Refills' },
        //add the column fields here Dont move the first & last fields the css is changes


        { name: 'Action', header: '', type: 'Action', editable: false, field: 'name', style: { width: '4%', borderRight: '1px solid #dedede' }, className: '' },
    ]

    useEffect(() => {
        getOptions();
    }, []);

    useEffect(() => {
        setEditOptions();
    }, [selectedData])

    //Set the Options
    const getOptions = () => {
        fetchMethodRequest('GET', item.searchApi).then(res => {
            let arr = res[item.searchApi]
            if (arr && arr.length) {
                arr = arr.map(obj => {
                    return { label: obj.name, value: obj._id, data: obj };
                })
            }
            setOptions(arr);
        })
    }

    //set the selected data on the edit form
    const setEditOptions = () => {
        if (formType === 'edit') {
            let arr = selectedData?.drugsData && selectedData?.drugsData.length > 0 ? selectedData.drugsData : [];
            setValue('drugsData', arr);
            setSelectedOptions(arr);
        }
    }

    /**
     * 
     * @param {Number} i 
     * @param {Object} item 
     * @param {Function}} Controller 
     * @param {Function} control 
     * @param {Function} getValues 
     * @param {Function} setValue 
     * @param {Object} errors 
     * @returns 
     */
    let getDropdownMultiselect = (i, item, Controller, control, getValues, setValue, errors) => {//Render the Multiple select field

        return <div className="col-lg-4">
            <Controller
                name={item.name}
                control={control}
                render={({ field, fieldState }) => (
                    <DoMultiSelectField
                        input={field}
                        id={field.id}
                        item={item}
                        name={field.name}
                        field={field}
                        fieldState={fieldState}
                        errors={errors}
                        label={item.label}
                        options={options}
                        optionLabel={item.optionLabel}
                        placeholder={item.placeholder}
                        onChange={onChange}
                    />)}
            />
        </div>
    }

    /**
     * 
     * @param {Array} values 
     */
    const onChange = (values) => {//Onchange the Multiple select
        values = values ? values : [];
        let seletedArr = []
        options.map(obj => {
            if (values.includes(obj.value)) {
                let index = selectedOptions.findIndex(selectedOption => selectedOption._id == obj.value);
                seletedArr.push(index >= 0 ? selectedOptions[index] : obj.data);
            }
        })
        if (selectedOptions?.length > 0 && !selectedOptions[selectedOptions.length - 1]._id) {
            seletedArr.push(selectedOptions[selectedOptions.length - 1]);
        }
        setSelectedOptions(seletedArr);
        seletedArr = seletedArr.filter(obj => obj._id);
        setValue('drugsData', seletedArr);
        // setValue('drugsName', seletedArr.map(o => o.name));
    }


    /**
     * 
     * @param {Object} rowData 
     * @param {String} field 
     * @param {String} value 
     */
    const onEditorValueChange = (rowData, field, value) => {
        const updatedRows = selectedOptions.map(row => {
            if (row._id === rowData._id) {
                return { ...row, [field]: value };
            }
            return row;
        });

        setSelectedOptions(updatedRows);
        setValue('drugsData', updatedRows.filter(obj => obj._id));
    };

    /**
     * 
     * @param {Object} rowData 
     * @param {Object} col 
     * @returns 
     */
    const inputTextEditor = (rowData, col) => {//Render the DataTable Input field
        let data = rowData;
        const onChange = (e) => {
            data[col.name] = e.target.value
            onEditorValueChange(data, col.name, e.target.value)
        }
        return (
            <input
                type={col.type ? col.type : "text"}
                value={data[col.name]}
                className="drugs_input_field"
                onChange={onChange}
                placeholder={col.placeholder}
            />
        );
    };

    /**
     * 
     * @param {Object} rowData 
     * @param {String} action 
     */
    const onClickActions = async (e, rowData, action) => {//
        e.preventDefault();
        let newOptions = [];
        if (action === 'delete') {//Delete the Drug from the selected collection
            newOptions = selectedOptions.filter(option => option._id !== rowData._id);
            setSelectedOptionsFun(newOptions);
        } else if (action === 'save') {//Add the Drug to collection & selected collection
            fetchMethodRequest('POST', apiCalls.drugs, rowData).then(res => {
                if (res && res.respMessage) {
                    showToasterMessage(res.respMessage, 'success');
                    getOptions();
                    setTimeout(() => {
                        let newOptions = selectedOptions
                        newOptions[newOptions.length - 1] = res.details;
                        setSelectedOptionsFun(newOptions)
                    }, 300);
                } else {
                    showToasterMessage(res.errorMessage, 'error');
                }

            })
        }
    };

    const setSelectedOptionsFun = (newOptions) => {
        setSelectedOptions(newOptions);
        newOptions = newOptions.filter(obj => obj._id)
        setValue(item.name, newOptions.map(obj => obj._id));
        // setValue('drugsName', newOptions.map(obj => obj.name));
        setValue('drugsData', newOptions);
        setCustomFieldValidation({ save: true });
    }

    const columnBody = (rowData, col) => {
        if (col.name == 'Action' && !rowData._id) {
            return <div className="d-flex justify-content-between p-0">
                <FontAwesomeIcon
                    className='drugsfield_action_deleteicon'
                    color={'white'}
                    icon={faTimes}
                    size='lg'
                    title="Remove"
                    data-toggle="tool-tip"
                    onClick={(e) => onClickActions(e, rowData, 'delete')}
                />
                <FontAwesomeIcon
                    className='drugsfield_action_saveicon'
                    color={'white'}
                    icon={faCheck}
                    size='lg'
                    title="Save"
                    data-toggle="tool-tip"
                    onClick={(e) => onClickActions(e, rowData, 'save')}
                />
            </div>
        }
        else if (col.editable || !rowData._id) {
            return inputTextEditor(rowData, col)
        }
        return rowData[col.name]
    }

    const addNewDrug = (e) => {
        e.preventDefault();
        if (selectedOptions.every((obj) => obj._id)) {
            setSelectedOptions([...selectedOptions, {}]);
            setCustomFieldValidation({ save: false, errorMessage: 'Save the Drug' });
        } else {
            showToasterMessage('Save the Drug', 'warning');
        }
    }

    return <div className="drug_datatable">
        {options && <><div>
            <div className="d-flex align-items-center mb-1 text-center">
                <label>Drugs
                {item.required && <FontAwesomeIcon
                    color='red'
                    icon={faAsterisk}
                    style={{ width: '7px', marginLeft: "2px" }}
                />}
                </label>
                <Button rounded className="drugsfield_add_icon" type="button"><FontAwesomeIcon
                    icon={faPlus}
                    size='lg'
                    data-toggle="tool-tip"
                    title={"Add"}
                    type="button"
                    onClick={addNewDrug}
                /></Button>
            </div>
            <div className="row">{getDropdownMultiselect(i, item, Controller, control, getValues, setValue, errors)}</div>
        </div>
            {selectedOptions?.length > 0 &&
                <DataTable value={selectedOptions} className="mt-3">
                    {formFields.map((col) => {
                        return <Column field={col.field} header={col.header} body={(rowData) => columnBody(rowData, col)} style={col.style} />
                    })}
                </DataTable>}
        </>}
    </div>

}