import React, { useRef, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faFilePdf, faTrashAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button as ReactStrapButton } from 'reactstrap';
import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import config from '../../../config/config';

const Dropzone = (props) => {
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { item, field, setValue, getValues } = props;
  const url = `uploads/uploadAttachments?type=${item.filePath.toLowerCase()}`;
  const dropzoneStyle = {
    border: '2px dashed rgb(160, 160, 160)',
    borderRadius: '5px',
    padding: '20px',
    textAlign: 'center',
    height: '20vh',
    width: '35vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  };

  const iconButtonStyle = {
    width: '50px',
    height: '50px',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '24px',
    color: '#fff',
    padding: '0',
    margin: '0',
  };

  const iconContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };


  const previewContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '10px',
    marginTop: '10px',
    maxHeight: '200px',  // Adjustable based on your needs
    overflowY: 'auto',  // Allow vertical scrolling
    width: '100%',
    padding: '5px',
    borderBottom: '1px solid #ccc',
  };


  const fileContainerStyle = index => ({
    position: 'relative',
    width: `${100 / Math.min(4, files.length)}%`, // Dynamically calculate width to fit up to 4 items per row
    maxWidth: '220px', // Ensure this is enough for the file icon and name
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '5px', // Add some margin
    paddingTop: '10px',
    boxSizing: 'border-box',
  });


  const removeIconStyle = {
    position: 'absolute',
    top: '2px',
    right: '2px',
    cursor: 'pointer',
    color: 'white',
    backgroundColor: '#a0a0a0',
    borderRadius: '50%',
    padding: '2px',
    width: '16px',
    height: '16px',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    lineHeight: '16px',
    zIndex: 10,
  };

  const fileNameStyle = {
    fontSize: '12px',
    color: '#333',
    textAlign: 'center',
    wordBreak: 'break-all',  // Allows words to break and wrap
    maxWidth: '200px',  // Ensure the width aligns with the maximum container width
    marginTop: '5px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',  // Keeps the text in a single line with ellipsis
  };


  const updatePreviewContainerStyle = () => {
    const fileCount = files.length;
    return {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      gap: '10px',
      marginTop: '10px',
      maxHeight: fileCount > 8 ? '200px' : 'auto',
      overflowY: fileCount > 8 ? 'scroll' : 'hidden',
      width: '100%',
      padding: '5px',
      maxHeight: '200px',  // Adjustable based on your needs
      overflowY: 'auto',
    };
  };

  const calculateIconSize = (fileCount) => {
    if (fileCount <= 5) {
      return '3x';
    } else if (fileCount <= 10) {
      return '2x';
    } else if (fileCount <= 15) {
      return '1x';
    } else if (fileCount <= 25) {
      return '0.2px';
    }
  };

  const iconSize = calculateIconSize(files.length);

  useEffect(() => {
    if (field.value && Array.isArray(field.value)) {
      const preloadedFiles = field.value.map(filename => {
        if (typeof filename === 'string') {  // Ensure filename is a string
          return {
            file: null,
            objectURL: `${config.imgUrl}/${item.filePath.toLowerCase()}/${filename}`,
            isPdf: filename.endsWith('.pdf'),
            filename: filename,
            preloaded: true  // Flag to indicate that the file is pre-loaded
          };
        } else {
          console.error('Expected filename to be a string, received:', typeof filename);
          return null;  // Return null or handle the error as needed
        }
      }).filter(file => file !== null);  // Filter out any null entries
      setFiles(preloadedFiles);
    }
  }, [field.value]);
  
  // Handle file removal
  const handleFileRemove = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  
    // Clear file input
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  
    const updatedFileNames = updatedFiles.map(file => file.filename);
    setValue(field.name, updatedFileNames);
  
  };
  

  // Handle new file drops
  const onDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map(file => ({
      file: file,
      objectURL: URL.createObjectURL(file),
      isPdf: file.name.endsWith('.pdf'),
      filename: file.name,
      preloaded: false  // Flag indicating these are new files
    }));
    setFiles(prev => [...prev, ...newFiles]);
    uploadFilesToServer(acceptedFiles);
  };



  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: props.accept || 'application/pdf',
    noClick: true,
    noKeyboard: true,
    multiple: true,
    onDrop: onDrop,
  });



  const uploadFilesToServer = async (acceptedFiles) => {
    setLoading(true);
    let filenames = []; // Collect filenames for state update
  
    for (const file of acceptedFiles) {
      try {
        const response = await fetchMethodRequest('POST', url, file, 'upload');
        if (response && response.respCode) {
          const uploadedFileName = response?.fileName[0].name || file.name;
          filenames.push(uploadedFileName);
        } else {
          showToasterMessage("Failed to upload file", 'error');
        }
      } catch (err) {
        console.error(err);
        showToasterMessage("Error uploading file", 'error');
      }
    }
  
    // Update filenames in the state after all uploads, appending to existing list
    let val = getValues(field.name) ? getValues(field.name) : [];
    setValue(field.name, [...val, ...filenames]);
    setLoading(false);
  }; 


  const onFileUploadClick = () => {
    fileInputRef.current.click();
  };
  
  const handleOpenPdf = (file) => {
    
    if (file.objectURL) {
      window.open(file.objectURL, '_blank');
    } else {
      console.error("PDF URL not found");
      showToasterMessage("PDF URL not available", 'error');
    }
  };
  

  return (
    <div>
      <label>Patient File Uploads</label>
      <div {...getRootProps({ style: dropzoneStyle })}>
        <input
          {...getInputProps({ ref: fileInputRef })}
          type="file"
          accept={props.accept || 'application/pdf'}
          style={{ display: 'none' }}
          multiple={true}
        />
        {isLoading && <div>Loading...</div>}
        {files.length > 0 && !isLoading ? (
          <div id="file-preview-container" style={updatePreviewContainerStyle()}>
            {files.map((file, index) => (
              <div key={index} style={fileContainerStyle(files.length)} className="image-container">
                {file.isPdf ? (
                  <>
                    <FontAwesomeIcon icon={faFilePdf} size="2x" style={{ color: 'red' }}  onClick={() => handleOpenPdf(file)}/>
                    <div style={fileNameStyle}>{file.filename}</div>
                    <span style={removeIconStyle} onClick={() => handleFileRemove(index)}>X</span>
                  </>
                ) : (
                  <>
                    <img src={file.objectURL} alt={`preview ${file.filename}`} className="preview-image"  onClick={() => handleOpenPdf(file)}/>
                    <div style={fileNameStyle}>{file.filename}</div>
                    <span style={removeIconStyle} onClick={() => handleFileRemove(index)}>X</span>
                  </>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="dropzone-content">
            <p className="box-heading">{props.label}</p>
            <div className="drop-text-container">
              <p className="drop-text">Drop files here or click the button to upload</p>
            </div>
            <div style={iconContainerStyle}>
              <ReactStrapButton onClick={onFileUploadClick} color='primary' style={iconButtonStyle}>
                <FontAwesomeIcon icon={faUpload} />
              </ReactStrapButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropzone;
