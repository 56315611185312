import React, { useState, useEffect,useContext } from 'react';
import { Card } from 'primereact/card'; 
import fetchMethodRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls';
import { faCircle, faBookmark, faBan, faCalendar,faTrash,faCaretDown,faAngleLeft, faAngleRight ,faCopy} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import formatDate from '../../UI/FormatDate/formatDate';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import DeleteRowModal from '../../Cruds/CommonModals/DeleteRowModal';
import { GlobalContext } from '../../App/App';
const AppointmentCards = () => {
  const [appointments, setAppointments] = useState([]);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const context = useContext(GlobalContext);


  useEffect(() => {
   getAppointments();
  }, []);

   const getAppointments = () =>{
     fetchMethodRequest('GET', apiCalls.appointments)
    .then(response => {
      if (response) {
          setAppointments(response.appointments);
      }
    })
    .catch(error => {
      console.error('Error fetching users:', error);
    });
   }
   
    useEffect(() => {
      const patient = context?.patientData || {};
      const clinic = context?.clinicData || {};
  
      const patientId = patient._id || null;
      const clinicId = clinic._id || null;
  
      if (patientId && clinicId) {
          const patientAppointments = appointments.filter(appointment => 
              appointment.patientId && appointment.patientId._id === patientId &&
              appointment.clinicId && appointment.clinicId._id === clinicId
          );
          patientAppointments.sort((a, b) => new Date(a.date) - new Date(b.date));
          setFilteredAppointments(patientAppointments);
      } else {
          const today = new Date();
          const todayString = formatDate.formatDate(today).split('T')[0];
  
          const todayAppointments = appointments?.filter(appointment => {
              try {
                  const appointmentDate = formatDate.formatDate(new Date(appointment.date)).split('T')[0];
                  return appointmentDate === todayString &&
                         appointment.clinicId && appointment.clinicId._id === clinicId;
              } catch (error) {
                  console.error('Error parsing date for appointment:', appointment, error);
                  return false;
              }
          }); 
          todayAppointments.sort((a, b) => new Date(a.date) - new Date(b.date));
          setFilteredAppointments(todayAppointments);
      }
  }, [appointments, context?.patientData, context?.clinicData]);
  
  
  const statusOptions = [
    { label: 'Unconfirmed', value: 'unconfirmed', color: '#FF6347' }, // Tomato
    { label: 'Confirmed', value: 'confirmed', color: '#FF4500' }, // Orange Red
    { label: 'Arrived', value: 'arrived', color: '#32CD32' }, // Lime Green
    { label: 'Ready', value: 'ready', color: '#FFD700' }, // Gold
    { label: 'In Room', value: 'inroom', color: '#1E90FF' }, // Dodger Blue
    { label: 'Checkout', value: 'checkout', color: '#FF69B4' }, // Hot Pink
    { label: 'Left Msg', value: 'leftmsg', color: '#8A2BE2' }, // Blue Violet
    { label: 'Bad Num', value: 'badnum', color: '#A52A2A' }, // Brown
    { label: 'E-mailed', value: 'emailed', color: '#20B2AA' }, // Light Sea Green
    { label: 'Texted', value: 'texted', color: '#FFA500' }, // Orange
    { label: 'E-Confirm Sent', value: 'econfirmsent', color: '#778899' }, // Light Slate Gray
    { label: 'E-Confirm Call', value: 'econfirmcall', color: '#DB7093' }, // Pale Violet Red
    { label: 'E-Confirm Fault', value: 'econfirmfault', color: '#2E8B57' }, // Sea Green
    { label: 'Web Sched', value: 'websched', color: '#6A5ACD' }, // Slate Blue
    { label: 'Out The Door', value: 'outthedoor', color: '#FF4500' } // Orange Red
];

    const colorNames = {
      '#D32F2F': 'Red',
      '#C2185B': 'Pink',
      '#7B1FA2': 'Purple',
      '#512DA8': 'Dark Purple',
      '#303F9F': 'Indigo',
      '#1976D2': 'Blue',
      '#0288D1': 'Light Blue',
      '#0097A7': 'Cyan',
      '#00796B': 'Teal',
      '#388E3C': 'Green',
      '#689F38': 'Lime',
      '#AFB42B': 'Yellow',
      '#F57C00': 'Orange',
      '#E64A19': 'Deep Orange',
      '#5D4037': 'Brown',
      '#795548': 'Dark Brown',
      '#004D40': 'Deep Teal',
      '#1B5E20': 'Dark Green',
      '#BF360C': 'Deep Red',
      '#3E2723': 'Dark Sienna',
      '#212121': 'Charcoal',
      '#000080': 'Navy Blue',
      '#8B0000': 'Dark Red',
      '#006400': 'Dark Green'
    };


  const onDeleteConfirm = async (appointment) =>{
    await setSelectedRow(appointment);
    setOpenDeleteModal(true);
  }

  const deleteSelectedRow = () =>{
    if(selectedRow){
      getDeleteAppointment(selectedRow)
    }
  }

  const getDeleteAppointment = (rowData) =>{
    if(rowData){
        const apiUrl = `${apiCalls.appointments}/${rowData._id}`
        fetchMethodRequest('DELETE', apiUrl)
          .then(response => {
            if (response) {
              showToasterMessage(response.respMessage, 'success'); 
              setOpenDeleteModal(false);
              getAppointments(); 

            }else{
              showToasterMessage(response.errorMessage, 'error');
            }
          })
          .catch(error => {
            console.error('Error fetching users:', error);
          });
     }
     
  }

  const nameToHex = Object.entries(colorNames).reduce((acc, [hex, name]) => {
    acc[name.toLowerCase()] = hex;
    return acc;
  }, {});

  const getStatusColor = (confirmationStatus) => {
    const status = statusOptions.find(option => option.value === confirmationStatus);
    return status ? status.color : '#000000';
  };

  // Get Delete Modal 
  const getDeleteRowModal = () =>{
    return (
      <DeleteRowModal
        openDeleteModal={openDeleteModal}
        closeDeleteModal={()=>{setOpenDeleteModal(false)}}
        deleteSelectedRow={deleteSelectedRow}
      />
    )
  }

  return (<>
  <div className="appointment-cards-container">
     {filteredAppointments && filteredAppointments.length === 0 && (
          <div style={{ color: 'grey', textAlign: 'left', marginTop: '20px' }}>
            No Appointments
          </div>
        )}
      {filteredAppointments.map((appointment) => (
        <Card key={appointment._id} className="appointment-card p-mb-2" style={{ display: 'flex', alignItems: 'center', padding: '1em', position: 'relative', backgroundColor:nameToHex[appointment.color.toLowerCase()] , marginBottom: '15px' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: '1', marginRight: '1em', color: 'white' }}>
              <div style={{ fontSize: '1em', fontWeight: 'bold' }}>
                {new Date(appointment.date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }).toUpperCase()}
              </div>
              <div style={{ fontSize: '1.2em', color: 'white', marginTop: '0.4em' }}>
                {new Date(appointment.fromTime).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })} -
                {new Date(appointment.toTime).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}
              </div>
            </div>
            <div style={{ flex: '2', color: 'white' }}>
              <div style={{ fontSize: '1.2em', fontWeight: 'bold', textTransform: 'capitalize', marginBottom: '0' }}>
                {appointment.patientId && appointment.patientId.firstName && appointment.patientId.lastName ?
                  `${appointment.patientId.firstName} ${appointment.patientId.lastName}` :
                  "No Patient"
                }
              </div>
              <p style={{ fontSize: '1.2em', marginTop: '0.1em', color: 'white' }}>Operatory: {appointment.handler.name}</p>
            </div>
            <div className="card-icons">
              <FontAwesomeIcon icon={faCircle} className='icon-circle' style={{ color: getStatusColor(appointment.confirmationStatus) }} />
              <FontAwesomeIcon icon={faTrash} className='icon-trash' onClick={()=>{onDeleteConfirm(appointment)}} />
            </div>
          </div>
        </Card>
      ))}
  </div>

    {openDeleteModal ? getDeleteRowModal() : null}

</> );
};

export default AppointmentCards;
