import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import DrugInputField from "./DrugInputField";
import PharmacyInputField from "./PharmacyInputField";
import configImages from "../../../../config/configImages";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from 'primereact/dialog';
import { useForm, Controller } from 'react-hook-form';
import DoFileUplaod from "../../../Form/Fields/DoFileUpload";
import html2pdf from 'html2pdf.js';
import moment from "moment";

const Prescriptions = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);
  const [rowData, setRowData] = useState({});
  const [loginRole, setLoginRole] = useState();

  const dataTableRef = useRef(null);//DataTable ref

  const sendStatusOptions = [
    { label: "Unsent", value: "Unsent", color: "danger" },
    { label: "InElectQueue", value: "InElectQueue", color: "primary" },
    { label: "SentElect", value: "SentElect", color: "primary" },
    { label: "Printed", value: "Printed", color: "primary" },
    { label: "Faxed", value: "Faxed", color: "primary" },
    { label: "CalledIn", value: "CalledIn", color: "primary" },
    { label: "GaveScript", value: "GaveScript", color: "primary" },
    { label: "Pending", value: "Pending", color: "danger" },
  ];

  const params = useParams();
  useEffect(() => {
    setLoginRole((JSON.parse(localStorage.getItem('loginCredentials'))).role);
    let screenPermissions = RolePermissions.screenPermissions("Prescriptions");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  let { handleSubmit, register, reset, setValue, getValues, formState: { errors }, control } = useForm();


  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        field: "patientName",
        type: "text",
        placeholder: "Patient Name",
        label: "Patient Name",
        header: "Patient Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "text",
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "patientName",
        fieldName: "name",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType: 'Link',
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
      },
      {
        name: "clinicId",
        type: "relateAutoComplete",
        placeholder: "Clinic Id",
        label: "Clinic Id",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Clinic Id",
        derivedValue: "clinicId=clinicId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "clinicId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: "Clinics",
        searchField: "abbrevation",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["abbrevation"],
        controllerName: "Clinics",
        searchApi: "clinics",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "clinicId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      // {
      //   name: "Clinics_abbrevation",
      //   type: "text",
      //   placeholder: "Clinics_abbrevation",
      //   label: "Clinics_abbrevation",
      //   header: "Clinics abbrevation",
      //   width: 110,
      //   parentField: "clinicId",
      //   id: "Clinics_abbrevation",
      //   displayinlist: "true",
      //   fieldType: "RACSubField",
      //   textAlign: "Center",
      //   subFieldName: "abbrevation",
      //   show: true,
      //   field: "Clinics_abbrevation",
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      // },
      {
        name: "patientId",
        type: "relateAutoComplete",
        placeholder: "Select a patient",
        label: "Patient Id",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Patient Id",
        derivedValue: "patientId=patientId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "patientId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: "Patients",
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [
          "name",
          "firstName",
          "lastName",
          "preferredName",
          "salutation",
          "email",
          "address",
          "address2",
          "city",
          "state",
          "language",
          "specialty",
          "feeSchedule",
        ],
        controllerName: "Patients",
        searchApi: "patients",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "patientId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      // {
      //   name: "Patients_name",
      //   type: "text",
      //   placeholder: "Patients_name",
      //   label: "Patients_name",
      //   header: "Patients name",
      //   width: 110,
      //   parentField: "patientId",
      //   id: "Patients_name",
      //   displayinlist: "true",
      //   fieldType: "RACSubField",
      //   textAlign: "Center",
      //   subFieldName: "name",
      //   show: true,
      //   field: "Patients_name",
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      // },


      {
        name: "provider",
        type: "relateAutoComplete",
        placeholder: "Provider",
        label: "Provider",
        width: "150px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Provider",
        derivedValue: "provider=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "provider",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: 1007,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [
          "firstName",
          "lastName",
          "email",
          "Id",
          "education",
          "doctorDesignation",
          "specialization",
          "department",
          "Certificates",
          "languages",
          "address",
          "address2",
          "city",
          "state",
        ],
        controllerName: "Users",
        searchApi: "users",
        isMultiple: false,
        textAlign: "Center",
        show: false,
        field: "provider",
        showOrHideFields: [],
        fieldName: "provider",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "pharmacy",
        type: "relateAutoComplete",
        placeholder: "Pharmacy",
        label: "Pharmacy",
        width: "150px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Pharmacy",
        derivedValue: "pharmacy=pharmacy=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "pharmacy",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: "Pharmacies",
        searchField: "storeName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [
          "name",
          "storeName",
          "address",
          "address2",
          "city",
          "note",
        ],
        controllerName: "Pharmacies",
        searchApi: "pharmacies",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "pharmacy",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },

      // {
      //   name: "Pharmacies_storeName",
      //   type: "text",
      //   placeholder: "Pharmacies_storeName",
      //   label: "Pharmacies_storeName",
      //   header: "Pharmacies storeName",
      //   width: 110,
      //   parentField: "pharmacy",
      //   id: "Pharmacies_storeName",
      //   displayinlist: "true",
      //   fieldType: "RACSubField",
      //   textAlign: "Center",
      //   subFieldName: "storeName",
      //   show: true,
      //   field: "Pharmacies_storeName",
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      // },
      {
        name: "drugs",
        type: "text",
        placeholder: "Drugs",
        label: "Drugs",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Drugs",
        derivedValue: "drugs=drugs=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "drugs",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        fieldType: 'Array',
        field: "drugsName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        style: {
          maxWidth: '200px',
          display: 'block',
          overflow: 'hidden',
          whiteSpace: 'nowrap', /* Correct property to prevent wrapping */
          textOverflow: 'ellipsis',
        }
      },
      {
        name: "notes",
        type: "text",
        placeholder: "Notes",
        label: "prescriptions Notes",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "prescriptions Notes",
        derivedValue: "notes=notes=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "notes",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "notes",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        style: {
          maxWidth: '500px',
          display: 'block',
          overflow: 'hidden',
          whiteSpace: 'nowrap', /* Correct property to prevent wrapping */
          textOverflow: 'ellipsis',
        }
      },
      {
        name: "sendStatus",
        type: "dropDown",
        placeholder: "Send Status",
        label: "Send Status",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Send Status",
        derivedValue: "sendStatus=sendStatus=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "sendStatus",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: sendStatusOptions,
        fieldType: 'dropDown',
        textAlign: "Center",
        show: true,
        field: "sendStatus",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];

    data.push({
      "show": true,
      "textAlign": "center",
      "width": 60,
      "fieldType": "Actions",
      "field": "Actions",
      "header": "Actions",
      "filter": false,
      "sortable": false,
      displayInSettings: true,
    });
    return data;
  };

  /**
   * 
   * @param {Object} rowData 
   * @param {Object} col 
   * @returns Array
   */
  const custonActionIcon = (rowData, col) => {
    return (rowData?.uploadFilePath || rowData?.providerSignature) ? [] : ['edit', 'delete', 'fileUpload'];
  }

  const drugsCustomType = (customProps) => {
    return <DrugInputField {...customProps} />
  }

  const drugsValidation = (yup) => {
    return yup.array().required('Drugs is a required field');
  }

  const pharmacyCustomType = (customProps) => {
    return <PharmacyInputField {...customProps} loginRole={loginRole} />
  }

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "clinicId",
        type: "relateAutoComplete",
        placeholder: "ClinicId",
        label: "ClinicId",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "clinicId=clinicId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "clinicId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: "Clinics",
        searchField: "fullClinicName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["fullClinicName"],
        controllerName: "Clinics",
        searchApi: "clinics",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "patientId",
        type: "relateAutoComplete",
        placeholder: "Select a patient",
        label: "PatientId",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "patientId=patientId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "patientId",
        hasDependency: "true",
        hasDependencyField: 'clinicId',
        fieldForKey: 'clinicId',
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: "Patients",
        searchField: "fullName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [
          "name",
          "firstName",
          "lastName",
          "preferredName",
          "salutation",
          "email",
          "address",
          "address2",
          "city",
          "state",
          "language",
          "specialty",
          "feeSchedule",
        ],
        controllerName: "Patients",
        searchApi: apiCalls.patients,
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "provider",
        type: "relateAutoComplete",
        placeholder: "Provider",
        label: "Provider",
        width: "150px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "provider=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        defaultFilterCriteria: [{ key: 'role', value: config.doctorRole, type: 'regexOr' }],
        id: "provider",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        hasDependencyField: 'clinicId',
        hasDependency: "true",
        hasDependencyFieldType: 'in',
        fieldForKey: 'clinicsId',
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: 1007,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [
          "firstName",
          "lastName",
          "email",
          "Id",
          "education",
          "doctorDesignation",
          "specialization",
          "department",
          "Certificates",
          "languages",
          "address",
          "address2",
          "city",
          "state",
        ],
        controllerName: "Users",
        searchApi: apiCalls.users,
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        fieldName: "provider",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "pharmacy",
        type: "customType",
        customType: pharmacyCustomType,
        placeholder: "Pharmacy",
        label: "Pharmacy",
        width: "150px",
        disableLabel: true,
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "pharmacy=pharmacy=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "pharmacy",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: "Pharmacies",
        searchField: "storeName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [
          "name",
          "storeName",
          "address",
          "address2",
          "city",
          "note",
        ],
        controllerName: "Pharmacies",
        searchApi: apiCalls.pharmacies,
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "sendStatus",
        type: "dropDown",
        placeholder: "Send Status",
        label: "Send Status",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "sendStatus=sendStatus=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        value: 'Unsent',
        actions: [],
        actionsNumber: [],
        id: "sendStatus",
        customWidthClass: 'col-lg-4 mt-1',
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: sendStatusOptions,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "drugs",
        type: "customType",
        customType: drugsCustomType,
        placeholder: "Drugs",
        label: "Drugs",
        disableLabel: true,
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        searchApi: apiCalls.drugs,
        searchField: 'name',
        derivedValue: "drugs=drugs=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "drugs",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        customValidation: drugsValidation,
        customWidthClass: 'col-sm-12',
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "notes",
        type: "textarea",
        placeholder: "Notes",
        label: "Prescriptions Notes",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "notes=notes=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "notes",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        customWidthClass: 'col-sm-12 my-2',
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "providerSignature",
        type: "signature",
        placeholder: "Provider Signature",
        label: "Provider Signature",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        derivedValue: "providerSignature=providerSignature=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "providerSignature",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: loginRole == 'Admin',
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "text",
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "name",
        fieldName: "name",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "clinicId",
        type: "relateAutoComplete",
        placeholder: "ClinicId",
        label: "ClinicId",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "ClinicId",
        derivedValue: "clinicId=clinicId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "clinicId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: "Clinics",
        searchField: "abbrevation",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["abbrevation"],
        controllerName: "Clinics",
        searchApi: "clinics",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "clinicId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Clinics_abbrevation",
        type: "text",
        placeholder: "Clinics_abbrevation",
        label: "Clinics_abbrevation",
        header: "Clinics abbrevation",
        width: 110,
        parentField: "clinicId",
        id: "Clinics_abbrevation",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "abbrevation",
        show: true,
        field: "Clinics_abbrevation",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "patientId",
        type: "relateAutoComplete",
        placeholder: "Select a patient",
        label: "PatientId",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "PatientId",
        derivedValue: "patientId=patientId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "patientId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: "Patients",
        searchField: "firstName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [
          "name",
          "firstName",
          "lastName",
          "preferredName",
          "salutation",
          "email",
          "address",
          "address2",
          "city",
          "state",
          "language",
          "specialty",
          "feeSchedule",
        ],
        controllerName: "Patients",
        searchApi: "patients",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "patientId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Patients_name",
        type: "text",
        placeholder: "Patients_name",
        label: "Patients_name",
        header: "Patients name",
        width: 110,
        parentField: "patientId",
        id: "Patients_name",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "name",
        show: true,
        field: "Patients_name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Patients_firstName",
        type: "text",
        placeholder: "Patients_firstName",
        label: "Patients_firstName",
        header: "Patients firstName",
        width: 110,
        parentField: "patientId",
        id: "Patients_firstName",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "firstName",
        show: true,
        field: "Patients_firstName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Patients_lastName",
        type: "text",
        placeholder: "Patients_lastName",
        label: "Patients_lastName",
        header: "Patients lastName",
        width: 110,
        parentField: "patientId",
        id: "Patients_lastName",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "lastName",
        show: true,
        field: "Patients_lastName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Patients_preferredName",
        type: "text",
        placeholder: "Patients_preferredName",
        label: "Patients_preferredName",
        header: "Patients preferredName",
        width: 110,
        parentField: "patientId",
        id: "Patients_preferredName",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "preferredName",
        show: true,
        field: "Patients_preferredName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Patients_salutation",
        type: "text",
        placeholder: "Patients_salutation",
        label: "Patients_salutation",
        header: "Patients salutation",
        width: 110,
        parentField: "patientId",
        id: "Patients_salutation",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "salutation",
        show: true,
        field: "Patients_salutation",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Patients_email",
        type: "text",
        placeholder: "Patients_email",
        label: "Patients_email",
        header: "Patients email",
        width: 110,
        parentField: "patientId",
        id: "Patients_email",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "email",
        show: true,
        field: "Patients_email",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Patients_address",
        type: "text",
        placeholder: "Patients_address",
        label: "Patients_address",
        header: "Patients address",
        width: 110,
        parentField: "patientId",
        id: "Patients_address",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "address",
        show: true,
        field: "Patients_address",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Patients_address2",
        type: "text",
        placeholder: "Patients_address2",
        label: "Patients_address2",
        header: "Patients address2",
        width: 110,
        parentField: "patientId",
        id: "Patients_address2",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "address2",
        show: true,
        field: "Patients_address2",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Patients_city",
        type: "text",
        placeholder: "Patients_city",
        label: "Patients_city",
        header: "Patients city",
        width: 110,
        parentField: "patientId",
        id: "Patients_city",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "city",
        show: true,
        field: "Patients_city",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Patients_state",
        type: "text",
        placeholder: "Patients_state",
        label: "Patients_state",
        header: "Patients state",
        width: 110,
        parentField: "patientId",
        id: "Patients_state",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "state",
        show: true,
        field: "Patients_state",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Patients_language",
        type: "text",
        placeholder: "Patients_language",
        label: "Patients_language",
        header: "Patients language",
        width: 110,
        parentField: "patientId",
        id: "Patients_language",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "language",
        show: true,
        field: "Patients_language",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Patients_specialty",
        type: "text",
        placeholder: "Patients_specialty",
        label: "Patients_specialty",
        header: "Patients specialty",
        width: 110,
        parentField: "patientId",
        id: "Patients_specialty",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "specialty",
        show: true,
        field: "Patients_specialty",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Patients_feeSchedule",
        type: "text",
        placeholder: "Patients_feeSchedule",
        label: "Patients_feeSchedule",
        header: "Patients feeSchedule",
        width: 110,
        parentField: "patientId",
        id: "Patients_feeSchedule",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "feeSchedule",
        show: true,
        field: "Patients_feeSchedule",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "provider",
        type: "relateAutoComplete",
        placeholder: "Provider",
        label: "Provider",
        width: "150px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Provider",
        derivedValue: "provider=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "provider",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: 1007,
        searchField: "Id",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [
          "firstName",
          "lastName",
          "email",
          "Id",
          "education",
          "doctorDesignation",
          "specialization",
          "department",
          "Certificates",
          "languages",
          "address",
          "address2",
          "city",
          "state",
        ],
        controllerName: "Users",
        searchApi: "users",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "provider",
        showOrHideFields: [],
        fieldName: "provider",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Users_firstName",
        type: "text",
        placeholder: "Users_firstName",
        label: "Users_firstName",
        header: "Users firstName",
        width: 110,
        parentField: "provider",
        id: "Users_firstName",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "firstName",
        show: true,
        field: "Users_firstName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Users_lastName",
        type: "text",
        placeholder: "Users_lastName",
        label: "Users_lastName",
        header: "Users lastName",
        width: 110,
        parentField: "provider",
        id: "Users_lastName",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "lastName",
        show: true,
        field: "Users_lastName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Users_email",
        type: "text",
        placeholder: "Users_email",
        label: "Users_email",
        header: "Users email",
        width: 110,
        parentField: "provider",
        id: "Users_email",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "email",
        show: true,
        field: "Users_email",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Users_Id",
        type: "text",
        placeholder: "Users_Id",
        label: "Users_Id",
        header: "Users Id",
        width: 110,
        parentField: "provider",
        id: "Users_Id",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "Id",
        show: true,
        field: "Users_Id",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Users_education",
        type: "text",
        placeholder: "Users_education",
        label: "Users_education",
        header: "Users education",
        width: 110,
        parentField: "provider",
        id: "Users_education",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "education",
        show: true,
        field: "Users_education",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Users_doctorDesignation",
        type: "text",
        placeholder: "Users_doctorDesignation",
        label: "Users_doctorDesignation",
        header: "Users doctorDesignation",
        width: 110,
        parentField: "provider",
        id: "Users_doctorDesignation",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "doctorDesignation",
        show: true,
        field: "Users_doctorDesignation",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Users_specialization",
        type: "text",
        placeholder: "Users_specialization",
        label: "Users_specialization",
        header: "Users specialization",
        width: 110,
        parentField: "provider",
        id: "Users_specialization",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "specialization",
        show: true,
        field: "Users_specialization",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Users_department",
        type: "text",
        placeholder: "Users_department",
        label: "Users_department",
        header: "Users department",
        width: 110,
        parentField: "provider",
        id: "Users_department",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "department",
        show: true,
        field: "Users_department",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Users_Certificates",
        type: "text",
        placeholder: "Users_Certificates",
        label: "Users_Certificates",
        header: "Users Certificates",
        width: 110,
        parentField: "provider",
        id: "Users_Certificates",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "Certificates",
        show: true,
        field: "Users_Certificates",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Users_languages",
        type: "text",
        placeholder: "Users_languages",
        label: "Users_languages",
        header: "Users languages",
        width: 110,
        parentField: "provider",
        id: "Users_languages",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "languages",
        show: true,
        field: "Users_languages",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Users_address",
        type: "text",
        placeholder: "Users_address",
        label: "Users_address",
        header: "Users address",
        width: 110,
        parentField: "provider",
        id: "Users_address",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "address",
        show: true,
        field: "Users_address",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Users_address2",
        type: "text",
        placeholder: "Users_address2",
        label: "Users_address2",
        header: "Users address2",
        width: 110,
        parentField: "provider",
        id: "Users_address2",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "address2",
        show: true,
        field: "Users_address2",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Users_city",
        type: "text",
        placeholder: "Users_city",
        label: "Users_city",
        header: "Users city",
        width: 110,
        parentField: "provider",
        id: "Users_city",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "city",
        show: true,
        field: "Users_city",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Users_state",
        type: "text",
        placeholder: "Users_state",
        label: "Users_state",
        header: "Users state",
        width: 110,
        parentField: "provider",
        id: "Users_state",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "state",
        show: true,
        field: "Users_state",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "pharmacy",
        type: "relateAutoComplete",
        placeholder: "Pharmacy",
        label: "Pharmacy",
        width: "150px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Pharmacy",
        derivedValue: "pharmacy=pharmacy=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "pharmacy",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: "Pharmacies",
        searchField: "storeName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [
          "name",
          "storeName",
          "address",
          "address2",
          "city",
          "note",
        ],
        controllerName: "Pharmacies",
        searchApi: "pharmacies",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "pharmacy",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Pharmacies_name",
        type: "text",
        placeholder: "Pharmacies_name",
        label: "Pharmacies_name",
        header: "Pharmacies name",
        width: 110,
        parentField: "pharmacy",
        id: "Pharmacies_name",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "name",
        show: true,
        field: "Pharmacies_name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Pharmacies_storeName",
        type: "text",
        placeholder: "Pharmacies_storeName",
        label: "Pharmacies_storeName",
        header: "Pharmacies storeName",
        width: 110,
        parentField: "pharmacy",
        id: "Pharmacies_storeName",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "storeName",
        show: true,
        field: "Pharmacies_storeName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Pharmacies_address",
        type: "text",
        placeholder: "Pharmacies_address",
        label: "Pharmacies_address",
        header: "Pharmacies address",
        width: 110,
        parentField: "pharmacy",
        id: "Pharmacies_address",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "address",
        show: true,
        field: "Pharmacies_address",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Pharmacies_address2",
        type: "text",
        placeholder: "Pharmacies_address2",
        label: "Pharmacies_address2",
        header: "Pharmacies address2",
        width: 110,
        parentField: "pharmacy",
        id: "Pharmacies_address2",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "address2",
        show: true,
        field: "Pharmacies_address2",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Pharmacies_city",
        type: "text",
        placeholder: "Pharmacies_city",
        label: "Pharmacies_city",
        header: "Pharmacies city",
        width: 110,
        parentField: "pharmacy",
        id: "Pharmacies_city",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "city",
        show: true,
        field: "Pharmacies_city",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Pharmacies_note",
        type: "text",
        placeholder: "Pharmacies_note",
        label: "Pharmacies_note",
        header: "Pharmacies note",
        width: 110,
        parentField: "pharmacy",
        id: "Pharmacies_note",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "note",
        show: true,
        field: "Pharmacies_note",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "sendStatus",
        type: "text",
        placeholder: "Send Status",
        label: "Send Status",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Send Status",
        derivedValue: "sendStatus=sendStatus=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "sendStatus",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "sendStatus",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "drugs",
        type: "radio",
        placeholder: "Drugs",
        label: "Drugs",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Drugs",
        derivedValue: "drugs=drugs=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "drugs",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "radio",
        textAlign: "Center",
        show: true,
        field: "drugs",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "notes",
        type: "text",
        placeholder: "Notes",
        label: "prescriptions Notes",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "prescriptions Notes",
        derivedValue: "notes=notes=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "notes",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "notes",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "providerSignature",
        type: "text",
        placeholder: "Provider Signature",
        label: "Provider Signature",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "Provider Signature",
        derivedValue: "providerSignature=providerSignature=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "providerSignature",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "providerSignature",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.prescriptions}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  /**
   * 
   * @param {Object} rowData 
   * @param {Object} item 
   */
  const downloadPrescription = (rowData, item) => {//Download the Prescription in the PDF formate
    const options = {
      margin: 10, // Set the top margin as needed
      filename: `${rowData?.patientId?.name ? rowData.patientId.name + '_' : ''}Prescription.pdf`,//Download PDF file name
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
    if (rowData.uploadFilePath) {
      fetch(config.imgUrl + apiCalls.prescriptions + '/' + rowData.uploadFilePath)
        .then(response => response.blob())
        .then(blob => {
          var a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = options.filename;
          a.click();
          URL.revokeObjectURL(a.href);
        });
    } else {
      let htmlContent = `<div class='d-flex flex-wrap prescription_download h-100'>`;

      htmlContent += `<div class='col-12 main_header'>
        <img src=${configImages.prescriptionDowmloadImage} />
        <div class='provider_header'>
          <div >
            <div class="header">${rowData.provider.name ? rowData.provider.name : '-'}</div>
            <div>${rowData.provider.address ? rowData.provider.address : ''} </div>
            <div>${rowData.provider.city ? rowData.provider.city + ' , ' : ''}${rowData.provider.state ? rowData.provider.state + ' , ' : ''}  ${rowData.provider.zipCode ? rowData.provider.zipCode : '-'}</div>
          </div>
          <div >
            <div class="header d-flex">Office Email : <p class="email_content">${rowData.provider.email ? rowData.provider.email : '-'}</p></div>
            <div class="header d-flex">Office Phone : <p class="email_content">${rowData.provider.phone ? rowData.provider.phone : 'XXX XXX XXXX'}</p></div>
            <div class="header d-flex">NPI # : <p class="email_content">${rowData.provider.npi ? rowData.provider.npi : 'XXX XXX XXXX'}</p></div>
          </div>
        </div>
    </div>`;

      if (rowData.clinicId) {
        htmlContent += `<div class='col-6'>Clinic Name : <span class='header text-capitalize'>${rowData.clinicId.abbrevation}</span></div>`
      }
      let addressArr = [], stateArr = [], numberArr = [];
      rowData.patientId.address ? addressArr.push(rowData.patientId.address) : null;
      rowData.patientId.address2 ? addressArr.push(rowData.patientId.address2) : null;

      rowData.patientId.city ? stateArr.push(rowData.patientId.city) : null;
      rowData.patientId.state ? stateArr.push(rowData.patientId.state) : null;
      rowData.patientId.zipCode ? stateArr.push(rowData.patientId.zipCode) : null;


      rowData.patientId.phoneNumber ? numberArr.push(rowData.patientId.phoneNumber) : null;


      htmlContent += `<div class='col-12 my-2'>
        <div>Patient Name : <span class='header text-capitalize'>${rowData.patientId.name}</span></div>
        <div>DOB          : <span class='header text-capitalize'>${rowData.patientId.dateOfBirth ? moment(rowData.patientId.dateOfBirth).format(config.dateDBFormat) : ''}</span></div>
        <div class="d-flex">Address      : <span class='header text-capitalize'>
        ${addressArr.length > 0 ? `<span>${addressArr.join(", ")}</span><br/>` : ''}
        ${stateArr.length > 0 ? `<span>${stateArr.join(", ")}</span><br/>` : ''}
        ${numberArr.length > 0 ? `<span>${numberArr.join(", ")}</span>` : ''}
        </span>
        </div>
      </div>`;

      if (rowData.pharmacy) {
        let arrLine1 = [], arrLine2 = [], arrLine3 = [];
        rowData.pharmacy.storeName ? arrLine1.push(rowData.pharmacy.storeName) : null;
        rowData.pharmacy.address ? arrLine1.push(rowData.pharmacy.address) : null;
        rowData.pharmacy.address2 ? arrLine1.push(rowData.pharmacy.address2) : null;
        rowData.pharmacy.city ? arrLine2.push(rowData.pharmacy.city) : null;
        rowData.pharmacy.state ? arrLine2.push(rowData.pharmacy.state) : null;
        rowData.pharmacy.zipCode ? arrLine2.push(rowData.pharmacy.zipCode) : null;
        rowData.pharmacy.fax ? arrLine3.push(rowData.pharmacy.fax) : null;
        rowData.pharmacy.phone ? arrLine3.push(rowData.pharmacy.phone) : null;

        htmlContent += `<div class='col-12 d-flex'>Pharmacy : 
        <span class='header text-capitalize px-2'> ${arrLine1.length > 0 ? arrLine1.join(', ') + '<br/>' : ''}  ${arrLine2.length > 0 ? arrLine2.join(', ') + '<br/>' : ''}  ${arrLine3.length > 0 ? arrLine3.join(', ') : ''}</span>
      </div>`;
      }


      if (rowData.drugsData?.length > 0) {
        htmlContent += `<div class='col-12 '><div class='col-12 drug_header'>Drugs :</div>
        ${[...rowData.drugsData].map((drug, i) => {
          let html = "";
          html = `<div class='row mb-4'>
        <div class='col-1'>${i + 1}</div>
        <div class='col-11'>
          <div class="header p-0">${drug.name ? drug.name : ''}</div>
          <div class='d-flex p-0'>
            <div class="header  p-0 d-flex" >Dosage : <p>${drug.dosage ? drug.dosage : ''}</p></div>
            <div class="header px-4 p-0 d-flex" >Disp : <p>${drug.disp ? drug.disp : '0'}</p></div>
            <div class="header  p-0 d-flex" >Refills : <p>${drug.refills ? drug.refills : '0'}</p></div>
          </div>
          <div class="header p-0 d-flex">Instructions&nbsp;:&nbsp;<p>${drug.instructions ? drug.instructions : ''}</p></div>
        </div>
        </div>`
          return html;
        }).join('')
          }
    </div>`;
      }

      if (rowData.notes) {
        htmlContent += `<div class='col-12'>Prescriptions Notes : <span class="header">${rowData.notes}</span></div>`;
      }
      htmlContent += `<div class="col-12 d-flex justify-content-between">
      <div class="col-6">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15px 15px" fill="none" stroke="currentColor" stroke-width="2px" stroke-linecap="round" stroke-linejoin="round" style="height: 15px; width: 15px;margin-bottom:10px">
        <rect x="0" y="0" width="14" height="14" rx="0" ry="0"></rect>
        <line x1="1" y1="1" x2="14" y2="14"></line>
        <line x1="14" y1="1" x2="1" y2="14"></line>
      </svg>
      <div><i class="pi pi-stop" style=" fontSize: '1rem' "></i> Dispense as Written</div>
      <div class="mx-3">&nbsp;Generic Substitution Permited</div>
      <p>Date : ${rowData.providerSignature ? `<span>${moment(rowData.created).format(config.dateDBFormat)}</span>` : ' ____________'}</p>
      </div>
      <div class="col-4 ${rowData.providerSignature ? '' : "signature"}">
      ${rowData.providerSignature ? `<img class="signature_print" src='${rowData.providerSignature}'/>` : `<span class="signature_line">_________________________________</span>`}
        <p class="">Signature of Provider</p>
      </div>
      </div></div>`;

      // Download PDF
      html2pdf(`<div class=''>${htmlContent}</div>`, options).outputPdf().then((pdf) => {
        pdf.save('hhh');
      });
    }
  }

  /**
    * 
    * @param {Object} rowData 
    * @param {Object} item 
    * @returns 
    */
  const customActionButtons = (data) => {//Custom icons
    const { item, tableItem } = data;
    return <span>
      <FontAwesomeIcon
        className='genderIconAlignment '
        color='white'
        icon='download'  // Change this line to use the copy icon
        data-toggle="tool-tip"
        title="Download as PDF"
        style={{ color: '#3c6177', width: '16', marginLeft: 10, cursor: 'pointer' }}
        onClick={() => downloadPrescription(item, tableItem)} />
    </span>
  }


  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  return (
    <span>
      {loginRole &&
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={true}
          PrintRequired={isEditPermission}
          // globalSearch={'Display Name/Email'}
          // type='Prescriptions'
          // apiUrl={apiCalls.Prescriptions}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          fileUploadRequired={true}
          fileUploadData={{ url: apiCalls.prescriptionUpload, acceptType: 'application/pdf', userClickUpload: true, }}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.prescriptions}
          globalSearch={"Clinic Id/Patient Id"}
          displayName="Prescriptions"
          type="Prescriptions"
          routeTo={apiCalls.prescriptions}
          displayViewOfForm="screen"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.prescriptions?.toLowerCase()}
          apiUrl={apiCalls.prescriptions}
          selectedId={params.id}
          actionDependentFunction={custonActionIcon}
          customActionButtons={customActionButtons}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="prescriptions"
          apiUrl={apiCalls.prescriptions}
        />
      ) : null}
    </span>
  );
};

export default Prescriptions;
