import React, { useState, useEffect } from 'react';

function PDFViewer({ url }) {
  const [pdfUrl, setPdfUrl] = useState('');

  useEffect(() => {
    async function fetchPDF() {
      try {
        console.log('Fetching PDF from:', url);
        const pdfFetch = await fetch(url);
        if (!pdfFetch.ok) {
          throw new Error(`Failed to fetch PDF: ${pdfFetch.statusText}`);
        }

        const blob = await pdfFetch.blob();
        const objectUrl = URL.createObjectURL(blob);
        setPdfUrl(objectUrl);

        // Clean up the object URL after the component is unmounted
        return () => URL.revokeObjectURL(objectUrl);
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    }

    fetchPDF();
  }, [url]);

  return (
    <iframe
      src={pdfUrl}
      style={{ width: '100%', height: '100vh' }}
      frameBorder="0"
      title="PDF Viewer"
    />
  );
}

export default PDFViewer;

