import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useState, useEffect, } from "react";
import { useForm, Controller } from 'react-hook-form';
import { Badge } from 'primereact/badge';


import apiCalls from "../../../config/apiCalls";
import fetchMethodRequest from "../../../config/service";
import showToasterMessage from "../../UI/ToasterMessage/toasterMessage";
import { Button } from "primereact/button";;
import DoMultiSelectField from "../../Form/Fields/DoMultiSelectField";
import config from "../../../config/config";
import FormsFunctions from "../CommonFunctions/FormsFunctions";
export default function AppointmentForm(props) {
    const [formsData, setFormsData] = useState([]);
    const [formsOptions, setFormsOptions] = useState([]);
    const [hospitalId, setHospitalId] = useState({});
    // const [patientData, setPatientData] = useState({});
    const patientData  =props.patientId
    let { handleSubmit, register, reset, setValue, getValues, formState: { errors }, control, } = useForm();


    useEffect(() => {
        getFormsData();
        if (localStorage.HospitalData) {
            setHospitalId(JSON.parse(localStorage.getItem('HospitalData')));
            getDataFromServer( { criteria: [{ key: 'patientId', value: patientData._id, type: 'eq' }] })
        }
    }, [])

    const getFormsData = async () => {
        let arr = (await fetchMethodRequest("GET", apiCalls.templates))[apiCalls.templates]
        setFormsOptions(arr);
    }


    const getDataFromServer = (filterCriteria) => {
        let url = `${apiCalls.forms}?filter=${JSON.stringify(filterCriteria ? filterCriteria : {})}`
        fetchMethodRequest('GET', url).then((res) => {
            let arr = []
            if (res[apiCalls.forms] && res[apiCalls.forms].length > 0) {
                arr = res[apiCalls.forms].map((obj, i) => {
                    obj.sNo = i + 1;
                    return obj;
                })
            }
            setFormsData(arr);
        })
    }


    const onClick = async (e) => {
        e.preventDefault();
        let forms = getValues('forms');
        if (forms && forms.length > 0 && patientData._id) {
            let userBody = { forms,patientId : patientData }
            if (userBody.forms && userBody.forms.length) {
                userBody.forms = await userBody.forms.map((obj) => {
                    let data = {}
                    data.formName = obj._id;
                    data.templateData = FormsFunctions.templateTextConverter(obj).templateText;
                    data.questions = [];
                    data.patientSignature = "";
                    data.doctorSignature = "";
                    data.patientId = patientData;
                    data.hospitalId = hospitalId;
                    data.status = obj.filledByFrontDesk ? undefined : 'Form sent';
                    return data
                })
            }

            fetchMethodRequest('POST', apiCalls.multipleForms, userBody).then((res) => {
                if (res && res.respMessage) {
                    showToasterMessage(res.respMessage, 'success')
                    reset();
                    getDataFromServer({ criteria: [{ key: 'patientId', value: patientData._id, type: 'eq' }] })
                } else {
                    showToasterMessage(res.errorMessage, 'error')
                }
            })
        } else if (!forms && patientData._id) {
            showToasterMessage('Select the Forms', 'warning');
        } else if (!patientData._id) {
            showToasterMessage('Select the Patient', 'warning');
        }


    }

    let getDropdownMultiselect = (i, item) => {
        item = {
            name: "forms",
            placeholder: "Select Forms",
            label: "Select Forms",
            capitalizeTableText: false,
            id: "forms",
            displayinlist: "true",
            isFieldRequired: "false",
            required: true,
            displayOptionsInActions: false,
            searchField: "name",
            searchApi: apiCalls.templates,
            isMultiple: true,
            textAlign: "Center",
            show: true,
            options: formsOptions ? formsOptions : [],
            optionLabel: 'name',
            placeholder: 'Select the Forms',
            field: "forms",
            filter: true,
            mobile: true,
        }
        return (
            <div>
                <Controller
                    name={item.name}
                    control={control}
                    render={({ field, fieldState }) => (
                        <DoMultiSelectField
                            input={field}
                            id={field.id}
                            item={item}
                            name={field.name}
                            field={field}
                            fieldState={fieldState}
                            errors={errors}
                            label={item.label}
                            options={item.options}
                            optionLabel={item.optionLabel}
                            placeholder={item.placeholder}
                        />)}
                />
            </div>
        )
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <div>
                {rowData.status === 'Form sent' ? (
                    <Badge value="Form sent" severity="success" style={{ backgroundColor: '#87CEEB' }}></Badge> // Sky blue color
                ) : rowData.status === 'Completed' ? (
                    <Badge value="Completed" severity="success" style={{ backgroundColor: '#34A853' }}></Badge> // Green color
                ) : (
                    rowData.status
                )}
            </div>
        );
    };


    return <div>
        <div className="d-flex">
            <div style={{ width: '50%' }}>
                {formsOptions && getDropdownMultiselect(1, {})}
            </div>
            <div className="pt-4" style={{marginLeft:"6px" , marginTop:"1px"}}>
                <Button color='primary ' type="button" onClick={onClick}>Done</Button>
            </div>

        </div>
        <br />
        <DataTable value={formsData}>
            <Column field="sNo" header="S.NO" style={{width: '100px', textAlign: 'center'}} bodyStyle={{textAlign: 'center'}}></Column>
            <Column field="formNameName" header="Name" style={{textTransform:'capitalize'}} className="custom-header-text-align-left "></Column>
            <Column field="status" header="Status" body={statusBodyTemplate} style={{width: '120px', textAlign: 'center'}}></Column>
        </DataTable>
    </div>

}