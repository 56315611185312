import React, { useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faSync, faImage, faCamera, faUpload } from '@fortawesome/free-solid-svg-icons';
import { Dialog } from 'primereact/dialog';
import Webcam from 'react-webcam';
import { Button as ReactStrapButton } from 'reactstrap';
import './OrthoForm.scss';
import fetchMethodRequest from '../../../../config/service';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import { Tooltip } from 'primereact/tooltip'; // PrimeReact Tooltip import
const OrthoFormDropzoneComponent = (props) => {
  const [rotationDegrees, setRotationDegrees] = useState({});
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [useWebcam, setUseWebcam] = useState(false);
  const webcamRef = useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState({ upload: false, camera: false });
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    removeFile
  } = useDropzone({
    accept: 'image/*',
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      if (files.length >= 1) {
        showToasterMessage("Only one file is allowed", "warning");
        return;
      }
      const file = acceptedFiles[0];
      if (file) {
        const newFile = {
          ...file,
          objectURL: URL.createObjectURL(file),
          lastModified: file.lastModified,
          lastModifiedDate: file.lastModifiedDate,
          size: file.size,
          type: file.type,
          webkitRelativePath: file.webkitRelativePath || '',
          name: file.path.substring(file.path.lastIndexOf('/') + 1)
        };
        setFiles(prev => [...prev, newFile]);
        uploadFilesToServer(file);
      }
    }
  });
  
  useEffect(() => {
    if (props.patientId || props.resetUploads) {
      setFiles([]); // Clear the files array
      setRotationDegrees({}); // Reset rotation degrees to default
    }
  }, [props.patientId, props.resetUploads]); // Depend on both patientId and resetUploads
  
  const handleRotate = (event, file) => {
    event.stopPropagation();
    const currentAngle = rotationDegrees[file.path] || 0;
    const newAngle = (currentAngle + 90) % 360;
    setRotationDegrees({
      ...rotationDegrees,
      [file.path]: newAngle  // Use the updated path after upload
    });
  };
  
  const handleRemove = (event, fileToRemove) => {
    event.stopPropagation();
    setFiles(prevFiles => prevFiles.filter(file => file.path !== fileToRemove.path));
    URL.revokeObjectURL(fileToRemove.objectURL);   
    if (props.onFileUpload) {
      props.onFileUpload(fileToRemove.path, null);  // Use updated path after upload
    }
  
    if (props.onFileRemove) {
      props.onFileRemove(fileToRemove);
    }
  };

  const onBrowseClick = () => {
    setDialogVisible(true);
  };

  const handleModalClose = (choice) => {
    if (choice === 'camera') {
      setUseWebcam(true);
    } else if (choice === 'gallery') {
      fileInputRef.current.click();
    }
  };

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    fetch(imageSrc)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], `webcam-${Date.now()}.jpg`, { type: 'image/jpeg' });        
        const newFile = {
          ...file,
          objectURL: URL.createObjectURL(blob),
          path: file.name
        };
  
        // Add the file to the list and trigger the upload
        setFiles(prev => [...prev, newFile]);
        if (props.onFileUpload) {
          props.onFileUpload(file.name, newFile);
        }
        uploadFilesToServer(file);
  
        setUseWebcam(false); // Close the webcam after capturing
        setDialogVisible(false);  // Hide the dialog
      })
      .catch(err => {
        console.error("Error capturing image: ", err);
      });
  };
  


  const onFileUploadClick = () => {
    fileInputRef.current.click();
    setTooltipOpen(prev => ({ ...prev, upload: false }));
  };

  const onCameraClick = () => {
    setDialogVisible(true);
    setUseWebcam(true);
  };



  const uploadFilesToServer = (file) => {
   
  
    fetchMethodRequest('POST', props.url, file, 'upload')
      .then(async (response) => {
        if (response && response.respCode) {
          const uploadedFileName = response.fileName[0]?.name;  // Use the returned filename from the server  
          // Create a new File object with the new name but keep the original file's other data (type, size, etc.)
          const newFile = new File([file], uploadedFileName, {
            type: file.type,
            lastModified: file.lastModified,  // Retain the original lastModified date
          });
  
          // Update the files array with the newly named file
          setFiles((prev) => {
            return prev.map((f) =>
              f.path === file.path ? { ...f, name: uploadedFileName, path: uploadedFileName, file: newFile } : f
            );
          });
  
          // Pass the newly created file to the parent component via the callback
          if (props.onFileUpload) {
            props.onFileUpload(uploadedFileName, newFile); // Call the callback with the updated file info
          }
        } else if (response && response.errorCode) {
          showToasterMessage("Failed to upload file", 'error');
        }
      })
      .catch((err) => {
        console.error("Upload failed: ", err);
      });
  };
  
  

  const images = files.map(file => (
    <div key={file.path} className="image-container">
      <div className="icon-container">
        <div className="icon-background remove-icon" onClick={(event) => handleRemove(event, file)}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </div>
        <div className="icon-background rotate-icon" onClick={(event) => handleRotate(event, file)}>
          <FontAwesomeIcon icon={faSync} />
        </div>
      </div>
      <img
        src={file.objectURL}
        alt={`preview ${file.path}`}
        className="preview-image"
        style={{ transform: `rotate(${rotationDegrees[file.path] || 0}deg)` }}
      />
    </div>
  ));
  const onFileChange = () => {
    setDialogVisible(false);
  };
  const toggleTooltip = (tooltip) => {
    if (files.length === 0) {
      setTooltipOpen((prev) => ({ ...prev, [tooltip]: !prev[tooltip] }));
    }
  };

  return (
    <div {...getRootProps({ className: 'dropzone' })}>
      <input
        {...getInputProps({ ref: fileInputRef, onChange: onFileChange })}
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        multiple={false}
      />
      <Dialog
        header="Choose Image"
        visible={isDialogVisible}
        style={{
          width: useWebcam ? '50vw' : '18vw', // Conditional width
          height: useWebcam ? '100vh' : '23vh' // Constant height
        }}
        onHide={() => setDialogVisible(false)}
        className="dialog image-dialog"
      >
        {useWebcam ? (
          <div className="webcam-container">
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              mirrored={true}
              style={{ width: '100%', height: 'auto' }}
            />
            <div className="webcam-buttons">
              <button onClick={captureImage} className="p-button">Capture</button>
              <button onClick={() => setDialogVisible(false)} className="p-button">Cancel</button>
            </div>
          </div>
        ) : (
          <p></p>
        )}
      </Dialog>
      {files.length > 0 ? (
        <div className="preview-container">{images}</div>
      ) : (
        <div className="dropzone-content">
          <p className="box-heading">{props.label}</p>
          <div className="drop-text-container">
            <p className="drop-text">Drop an image here</p>
          </div>
          <div className="icon-buttons-container">
            <ReactStrapButton
              id="uploadButton" // Add an id for the tooltip target
              className="icon-button"
              onClick={onFileUploadClick}
              color='primary'
              onMouseEnter={() => toggleTooltip('upload')}
              onMouseLeave={() => toggleTooltip('upload')}
            >
              <FontAwesomeIcon icon={faUpload} />
            </ReactStrapButton>
            <Tooltip target="#uploadButton" content="Upload an image" />
            <ReactStrapButton
              id="cameraButton" // Add an id for the tooltip target
              className="icon-button"
              onClick={onCameraClick}
              color='primary'
              onMouseEnter={() => toggleTooltip('camera')}
              onMouseLeave={() => toggleTooltip('camera')}
            >
              <FontAwesomeIcon icon={faCamera} />
            </ReactStrapButton>
            <Tooltip target="#cameraButton" content="Open camera" />
          </div>
        </div>
      )}
    </div>
  );
};

export default OrthoFormDropzoneComponent;
