import React from 'react'
import { useState, useEffect, useRef } from "react";
import { Modal, ModalHeader, ModalBody, Card, CardBody, ButtonToolbar, Table, ButtonGroup, Row, Col, Badge } from 'reactstrap';
import { Dialog } from 'primereact/dialog';
import { withTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import Loader from '../../App/Loader';
import DoSelectField from '../Fields/DoSelectField';
import DoInputField from '../Fields/DoInputField';
import DoTextareaField from '../Fields/DoTextareaField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import fetchMethodRequest from '../../../config/service';
import DoCheckboxField from '../Fields/DoCheckboxField';
import * as yup from "yup";

function InsuranceBenifitInfoModal(props) {
  const [allBenifitInfoFormFields, setAllBenifitInfoFormFields] = useState(props.benifitInfoFormFields);
  const [othoFormFields, setOthoFormFields] = useState(props.othoFormFields);
  const [selectedOptions, setSelectedOptions] = useState([]);
  let [isLoading, setIsLoading] = useState(false);
  const [editRowDataID, setEditRowDataID] = useState();
  const [formType, setFormType] = useState(props.formType);
  let [sessionExpiryModal, setSessionExpiryModal] = useState(false);
  const [selectedCity, setSelectedCity] = useState();
  const [annualFormFields, setAnnualFormFields] = useState();
  const [frequencyFormFields, setFrequencyFormFields] = useState();
  const [orthoFormFields, setOrthoFormFields] = useState();
  const [simplifiedFormFields, setSimplifiedFormFields] = useState();
  const [categoryFormFields, setCategoryFormFields] = useState();
  const [benifitId, setBenifitId] = useState('');


  const categoryOptions = [
    { label: 'None', value: 'None' },
    { label: 'Diagnostic', value: 'Diagnostic' },
    { label: 'X-Ray', value: 'X-Ray' },
    { label: 'Preventive', value: 'Preventive' },
    { label: 'Restorative', value: 'Restorative' },
    { label: 'Endo', value: 'Endo' },
    { label: 'Oral Surgery', value: 'Oral Surgery' },
    { label: 'Crows', value: 'Crows' },
    { label: 'Ortho', value: 'Ortho' },
    { label: 'Prosth', value: 'Prosth' },
    { label: 'Perio', value: 'Perio' },
    { label: 'Maxilofacial Prosth', value: 'Maxilofacial Prosth' },
    { label: 'Accident', value: 'Accident' },
    { label: 'Adjuctive', value: 'Adjuctive' },
    { label: 'Implants', value: 'Implants' }
  ];

  const benifitType = [
    { label: 'ActiveCoverage', value: 'ActiveCoverage' },
    { label: 'Colnsurance', value: 'Colnsurance' },
    { label: 'Deductable', value: 'Deductable' },
    { label: 'CoPayment', value: 'CoPayment' },
    { label: 'Exclusions', value: 'Exclusions' },
    { label: 'Limitations', value: 'Limitations' },
    { label: 'WaitingPeriod', value: 'WaitingPeriod' },
  ]
  const timePeriod = [
    { label: 'None', value: 'None' },
    { label: 'ServiceYear', value: 'ServiceYear' },
    { label: 'CalenderYear', value: 'CalenderYear' },
    { label: 'LifeTime', value: 'LifeTime' },
    { label: 'Years', value: 'Years' },
    { label: 'NumbersInLast12M', value: 'NumbersInLast12M' },
  ]
  const coverageLevel = [
    { label: 'None', value: 'None' },
    { label: 'Individuals', value: 'Individuals' },
    { label: 'Family', value: 'Family' },
  ]

  const Qualifier = [
    { label: 'None', value: 'None' },
    { label: 'NumberOfService', value: 'NumberOfService' },
    { label: 'AgeLimit', value: 'AgeLimit' },
    { label: 'Visits', value: 'Visits' },
    { label: 'Years', value: 'Years' },
    { label: 'Months', value: 'Months' },
  ]

  const formFields = [
    { name: 'pat', header: 'Pat', type: 'text', editable: false, field: 'name', style: { width: '6%', borderLeft: '1px solid #dedede' }, className: '', placeholder: 'Pat' },
    { name: 'level', header: 'Level', type: 'text', editable: false, field: 'level', style: { width: '6%', borderLeft: '1px solid #dedede' }, className: '', placeholder: 'Level' },
    { name: 'type', type: 'dropDown', value: '', options: coverageLevel, header: 'Type', editable: true, field: 'type', style: { width: '8%' }, className: '', placeholder: 'Type' },
    { name: 'category', header: 'Category', type: 'dropDown', options: benifitType, editable: true, field: 'category', style: { width: '8%' }, className: '', placeholder: 'Category' },
    { name: 'percentage', header: '%', type: 'number', editable: true, field: 'percentage', style: { width: '5%' }, className: '', placeholder: '%' },
    { name: 'amount', header: 'Amt', type: 'number', editable: true, field: 'amount', style: { width: '5%' }, className: '', placeholder: 'Amt' },
    { name: 'timePeriod', header: 'Time Period', options: timePeriod, type: 'dropDown', editable: true, field: 'timePeriod', style: { width: '8%' }, className: '', placeholder: 'Time Period' },
    { name: 'qualifier', header: 'Qualifier', options: Qualifier, type: 'dropDown', editable: true, field: 'qualifier', style: { width: '8%' }, className: '', placeholder: 'Qualifier' },
    { name: 'quantity', header: 'Quantity', type: 'text', editable: true, field: 'quantity', style: { width: '8%' }, className: '', placeholder: 'Quantity' },
    { name: 'coverageLevel', header: 'Coverage Level', options: coverageLevel, type: 'dropDown', editable: true, field: 'coverageLevel', style: { width: '8%' }, className: '', placeholder: 'Coverage Level' },



    //add the column fields here Dont move the first & last fields the css is changes
    // { name: 'Action', header: '', type: 'Action', editable: false, field: 'name', style: { width: '6%', borderRight: '1px solid #dedede' }, className: '' },
  ]
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
    setFocus,
    control,
  } = useForm(
    {resolver: yupResolver( yup.object().shape({
      bws: yup.string().required('Bws is Required'),
      fmx: yup.string().required('Fmx is Required'),
      exams: yup.string().required('Exams is Required'),
      lifetimeMax: yup.string().required('Lifetime Max is Required'),
      orthothroughAge: yup.string().required('Ortho Through Age is Required'),
      annualMaxIndividual: yup.string().required('Annual Max is Required'),
    })),}
    );


  // Focusing the Required Fields
  useEffect(() => {
      if (Object.keys(errors).length > 0) {
        const firstErrorField = Object.keys(errors)[0];
        const errorElement = document.querySelector(`[name="${firstErrorField}"]`) || document.querySelector(`#${firstErrorField}`);
        if (errorElement) {
            errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          setFocus(firstErrorField);
        }
      }
  }, [errors, setFocus]);


  const  selectedRowInfo = props.tableRowData && props.tableRowData.benifitsInfo

  useEffect(() => {
    if(props.formType === "edit"){
      let fields = props.benifitInfoFormFields();
      fields.forEach((item) => {
        if(!(item.isEditFormHidden === true)){
          setValue(item.name,selectedRowInfo[item.name])
        }
      });
      setSelectedOptions(selectedRowInfo.otherBenifits ? selectedRowInfo.otherBenifits : [])
      setValue('otherBenifits', selectedRowInfo.otherBenifits);
      
      // formFields.forEach((col,ind)=> )
    }
    const BenifitInfoFields = props.benifitInfoFormFields();
    let annualFields = [];
    let frequencyFields = [];
    let orthoFields = [];
    let categoryFields =[];
    let simplifiedFormFields = [];
    BenifitInfoFields.map((item) => {
      if (item.displayInOrtho) {
        orthoFields.push(item)
      } else if (item.displayInFrequency) {
        frequencyFields.push(item)
      } else if (item.displayinSimplified) {
        simplifiedFormFields.push(item)
      }else if(item.displayInCategores){
        categoryFields.push(item);
      }else {
        annualFields.push(item)
      }
    })
    setAnnualFormFields(annualFields);
    setFrequencyFormFields(frequencyFields);
    setOrthoFormFields(orthoFields);
    setSimplifiedFormFields(simplifiedFormFields)
    setCategoryFormFields(categoryFields)

  }, []);


  const sendDataToServer = (formValues) => {
    let userBody = Object.assign({}, formValues);
    let validationExists;
    setIsLoading(true);
    if (formValues) {
      let method, apiUrl;
      if ((formType === 'edit' || props.editTrue) && (selectedRowInfo?._id || benifitId)) {
        method = 'PUT';
        apiUrl = `${props.apiUrl}/${selectedRowInfo?._id ?  selectedRowInfo._id : benifitId}`;
      } else {
        method = 'POST';
        apiUrl = props.apiUrl;
      }

      if(props.formType === "add"){
        userBody.patientId = props.patientId ;
      }else{
        userBody.patientId = props.tableRowData && props.tableRowData.patientId ? props.tableRowData.patientId : null;
      }


      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {

          if (response && response.respCode) {
            // await props.getDataFromServer(props.filterCriteria);
            showToasterMessage(response.respMessage, 'success');
            props.setOtherBenifitId(response.benifitsId)
            setBenifitId(response.benifitsId);
            if (props.displayViewOfForm === 'modal') {
              props.closeFormModal('save', response);
            } else {
              props.closeFormModal('save', response);
            }
            props.setBenifitInformation(userBody)
            // clearFormFields();
            // clearFormFields();
            // props.reset();
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, 'error');
          }
          setIsLoading(false);
        }).catch((err) => {
          return err;
        });
    } else {
      return
    }
  }

  const submit = (formValues) => {
    if (formValues) {
      props.setOtherBenifitsData(formValues)
      sendDataToServer(formValues)
    }
  }

  const onClickActions = async (e, rowData, action) => {//
    e.preventDefault();
    let newOptions = [];
    if (action === 'delete') {//Delete the Drug from the selected collection
      newOptions = selectedOptions.filter(option => option._id !== rowData._id);
      setSelectedOptions(newOptions);
    }

  };

  let closeFormModal = async () => {
    if(props.formType === "add"){
      setSelectedOptions([])
    }
    props.closeFormModal();
    
  }

  let getModalHeader = () => {
    let { formType } = props;
    return (
      <ModalHeader className="modal__header">
        {/* <button className="lnr lnr-cross modal__close-btn" type="button" onClick={closeFormModal} /> */}
        <p className="bold-text  modal__title">
          Benifit Info
        </p>
      </ModalHeader>
    )
  }

  let getDropdown = (i, item) => {
    return (
      <div className={`${item.customWidthClass ? item.customWidthClass : `col-md-6 `} px-4 mt-1 mb-2`}>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoSelectField
              markReq={item.required}
              input={field}
              id={field.id}
              item={item}
              name={field.name}
              field={field}
              label={item.label}
              fieldState={fieldState}
              errors={errors}
              options={item.options}
              optionLabel={item.optionLabel ? item.optionLabel : 'label'}
              placeholder={item.placeholder}
            // onChange={e => handleFnEnableControlsBasedOnValue2(e, item.dependent)}
            />)}
        />
      </div>
    )
  }
  let getDefault = (i, item) => {
    let { t } = props;
    return (
      <div className={`${item.customWidthClass ? item.customWidthClass : `col-md-6`} px-4 mb-2`}>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => (
            <DoInputField
              markReq={item.required}
              input={field}
              item={item}
              id={field.id}
              name={field.name}
              label={item.label ? item.label : null}
              field={field}
              type={item.type ? item.type : "text"}
              fieldState={fieldState}
              errors={errors}
              placeholder={item.placeholder ? item.placeholder : null}
            />)}
        />
      </div>
    )
  }
  const getCheckbox = (i, item) => {//It Render the Checkbox field

    return <div className='col-md-3 px-4'>
      <Controller
        name={item.name ? item.name : null}
        control={control}
        render={({ field, fieldState }) => (
          <DoCheckboxField
            input={field}
            id={field.id}
            item={item}
            label={item.label}
            name={field.name}
            field={field}
            fieldState={fieldState}
            errors={errors}
            placeholder={item.placeholder ? item.placeholder : null}
          />)}
      />
    </div>
  }

  const getEmptyDiv = (i,item) =>{

    return <div className='col-md-6'></div>

  }

  const onEditorValueChange = (rowData, field, value) => {
    const updatedRows = selectedOptions.map(row => {
      if (row.index === rowData.index) {
        return { ...row, [field]: value };
      }
      return row;
    });

    setSelectedOptions(updatedRows);
      // setSelectedOptions(rowData);
    setValue('otherBenifits', updatedRows);
  };

  const getTableFields = (rowData, col) => {
    let data = rowData;
    const onChange = (e) => {
      data[col.name] = e.target.value
      onEditorValueChange(data, col.name, e.target.value)
    }

    if (col.type == "dropDown") {
      return (
        <Dropdown
          value={data[col.name]}
          onChange={onChange}
          options={col.options}
          optionLabel="label"
          placeholder={col.placeholder}
          style={{ height: "48px", width: "100%" }}
          className="w-full md:w-14rem"
        />
      )
    }

    return (
      <input
        type={col.type ? col.type : "text"}
        value={data[col.name]}
        className="benifts_data_table"
        onChange={onChange}
        style={{ height: "48px" }}
        placeholder={col.placeholder}
      />

    );
  };

  const columnBody = (rowData, col) => {
    if (col.name == 'Action') {
      return <div className="d-flex justify-content-around p-0">
        <FontAwesomeIcon
          className='benifitDeleteActionIcon'
          color={'white'}
          icon={faTimes}
          size='lg'
          title="Remove"
          data-toggle="tool-tip"
          onClick={(e) => onClickActions(e, rowData, col, 'delete')}
        />
        <FontAwesomeIcon
          className='benifitSaveActionIcon'
          color={'white'}
          icon={faCheck}
          size='lg'
          title="Save"
          data-toggle="tool-tip"
        // onClick={(e) => onClickActions(e, rowData, 'save')}
        />
      </div>
    }
    return getTableFields(rowData, col)
  }



  const addBenifits = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedOptions([...selectedOptions, {index:selectedOptions.length}])
  }

  const getFields = () => {
    return (<div>

      {/* get simplified Benifit Year  check Box */}
      <div className='mt-4 mb-4' style={{ marginLeft: "1.4rem" }}><b>Simplified View</b></div>
      <div className='mt-4 mb-3' style={{ marginLeft: "1.4rem", }}> <b style={{ fontSize: '14px' }}>Benifit Year</b></div>
      <div className='form mb-4'>
        {simplifiedFormFields && simplifiedFormFields.map((item, i) => {
          return item.type === "checkbox" ? getCheckbox(i, item) : null
        })
        }
      </div>

      {/* get Annual Form Fields */}
      <div className='form mb-2'>
        {
          annualFormFields && annualFormFields.map((item, i) => {
            return item.type === "dropDown" ?
              getDropdown(i, item)
              : getDefault(i, item)
          })
        }
      </div>

      {/* get Frequency Form Fields */}
      <div className='mt-4 mb-4' style={{ marginLeft: "1.4rem" }}><b>Frequency</b></div>
      <div className='form mb-2'>
        {
          frequencyFormFields && frequencyFormFields.map((item, i) => {
            return item.type === "dropDown" ?
              getDropdown(i, item)
              : getDefault(i, item)
          })
        }
      </div>

      {/* get Ortho Form Fields */}
      <div className='mt-4 mb-4' style={{ marginLeft: "1.4rem" }}><b>Ortho</b></div>
      <div className='form mb-2'>
        {
          orthoFormFields && orthoFormFields.map((item, i) => {
            return item.type === "dropDown" ?
              getDropdown(i, item)
              : getDefault(i, item)
          })
        }
      </div>

      {/* get Categorys Form Fields */}
      <div className='mt-4 mb-2' style={{ marginLeft: "1.4rem" }}><b>Categories</b></div>
      <div className='form mb-2' style={{alignItems:"end"}}>
        {/* {getCategorys()} */}
        {
          categoryFormFields && categoryFormFields.map((item, i) => {
            return item.type === "dropDown" ?
              getDropdown(i, item)
                : item.type === "emptyField" ?
                 getEmptyDiv(i,item)
                  : getDefault(i, item)
          })
        }



      </div>

      {/* Add Other Benifits  */}
      <div className='mt-4 mb-3' style={{ marginLeft: "1.4rem" }}>
        <b>Other Benifits</b>
        <Button rounded className="drugsfield_add_icon" onClick={(e) => addBenifits(e)}><FontAwesomeIcon
          icon={faPlus}
          size='lg'
          data-toggle="tool-tip"
          title={"Show"}
          type="button"
          onClick={(e) => addBenifits(e)}
        /></Button>
      </div>
      {<div className='form mt-1 mb-2' >
        {true &&
          <DataTable value={selectedOptions} style={{ width: "100%", marginLeft: "1.4rem" }}>
            {formFields.map((col) => {
              return (
                <Column field={col.field} header={col.header} body={(rowData) => columnBody(rowData, col)} style={col.style} />
              )
            }
            )}

          </DataTable>}
      </div>}

    </div>)
  }

  let getButtonToolbar = () => {
    let { t } = props;
    return (
      <div className='d-flex mt-5'>
        <div className='col-12 px-0'>
          <span className='float-end'>
            <ButtonToolbar>
              {formType === 'add' ? <Button color='primary' type="button" onClick={() => closeFormModal()}>
                {('Cancel') ? ('Cancel') : 'Cancel'}
              </Button> : formType !== 'add' ? <Button color='primary' type="button" onClick={() =>
                // setFormType("view")
                closeFormModal()
              }>
                {('Cancel') ? ('Cancel') : 'Cancel'}
              </Button> : formType === 'add' ? <Button color='primary' type="button" onClick={() => closeFormModal()}>
                {('Cancel') ? ('Cancel') : 'Cancel'}
              </Button> : null}
              <Button color='primary' outline type="submit">
                {formType && formType === 'add' ?
                  ('Save') ? ('Save') : 'Save'
                  : ('Update') ? ('Update') : 'Update'
                }
              </Button>
            </ButtonToolbar>
          </span>
        </div>
      </div>
    )
  }



  let getModalBody = () => {
    return (
      <>
        <form onSubmit={handleSubmit(submit)} autoComplete={'off'}>
          <Loader loader={isLoading} />
          <div
            className={`row form mx-2`}
          >
            {getFields()}
          </div>
          {getButtonToolbar()}
        </form>
      </>
    )
  }


  return (
    <div>


      <Dialog
        visible={props.isOpenBenifitInfo}
        onHide={closeFormModal}
        draggable={false}
        style={{ width: '80vw' }}
        header={getModalHeader()}
        closeOnEscape={false}
      >
        {
          <ModalBody className="modal__body mb-0 pt-4">
            <Card className='pb-0 cardForListMargin'>
              <CardBody className='tableCardBody'>
                {getModalBody()}
              </CardBody>
            </Card>
          </ModalBody>
        }
      </Dialog>


    </div>
  )
}

export default InsuranceBenifitInfoModal
