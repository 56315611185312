import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import Tooltip from '@material-ui/core/Tooltip';
import { InputText } from 'primereact/inputtext';

const DoColorPickerWithTooltip = ({ field, item }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState('');
  const [selectedColor, setSelectedColor] = useState(field.value);

  useEffect(() => {
    const elements = document.getElementsByClassName('sketch-picker color-picker');

    if (elements && elements.length > 0) {
      const element = elements[0];

      if (element?.childNodes?.length > 0) {
        element.childNodes[0].style.display = 'none';
        element.childNodes[1].style.display = 'none';
        element.childNodes[2].style.display = 'none';
      }
    }
  }, []);

  useEffect(() => {
    if (field.value) {
      handleColorChange(field.value);
    }
  }, [field.value]);

  const customColors = [
    '#D32F2F', '#C2185B', '#7B1FA2', '#512DA8', '#303F9F',
    '#1976D2', '#0288D1', '#0097A7', '#00796B', '#388E3C',
    '#689F38', '#AFB42B', '#F57C00', '#E64A19', '#5D4037',
    '#795548', '#004D40', '#1B5E20', '#BF360C', '#3E2723',
    '#212121', '#000080', '#8B0000', '#006400'
  ];

  const colorNames = {
    '#D32F2F': 'Red',
    '#C2185B': 'Pink',
    '#7B1FA2': 'Purple',
    '#512DA8': 'Dark Purple',
    '#303F9F': 'Indigo',
    '#1976D2': 'Blue',
    '#0288D1': 'Light Blue',
    '#0097A7': 'Cyan',
    '#00796B': 'Teal',
    '#388E3C': 'Green',
    '#689F38': 'Lime',
    '#AFB42B': 'Yellow',
    '#F57C00': 'Orange',
    '#E64A19': 'Deep Orange',
    '#5D4037': 'Brown',
    '#795548': 'Dark Brown',
    '#004D40': 'Deep Teal',
    '#1B5E20': 'Dark Green',
    '#BF360C': 'Deep Red',
    '#3E2723': 'Dark Sienna',
    '#212121': 'Charcoal',
    '#000080': 'Navy Blue',
    '#8B0000': 'Dark Red',
    '#006400': 'Dark Green'
  };


  const handleSwatchHover = (color) => {
    const hexColor = color.hex ? color.hex.toUpperCase() : color;
    const colorName = colorNames[hexColor] || hexColor;
    setTooltipTitle(colorName);
    setTooltipOpen(true);
  };

  const handleSwatchLeave = () => {
    setTooltipOpen(false);
  };

  const handleColorChange = (color) => {
    const hexColor = color.hex ? color.hex.toUpperCase() : color;
    const colorName = colorNames[hexColor] || hexColor;
    setSelectedColor(colorName);
    field.onChange(colorName);
  };

  return (
    <div>
      <Tooltip title={tooltipTitle} open={tooltipOpen} placement="top" style={{ width: '200px' }}>
        <div onMouseLeave={handleSwatchLeave}>
          <SketchPicker
            color={field.value ? field.value : ''}
            // onChange={color => field.onChange(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`)}
            onChangeComplete={field.onChange}
            onSwatchHover={handleSwatchHover}
            presetColors={customColors}
            className='color-picker'
            {...field}
          />
        </div>
      </Tooltip>
      <div style={{ paddingTop: '15px' }}>
        <InputText value={selectedColor ? selectedColor : null} onChange={(e) => setSelectedColor(e.target.value)} disabled={true} />
      </div>
    </div>
  );
};

export default DoColorPickerWithTooltip;
