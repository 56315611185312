import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React from 'react';

const StatusDetailsTable = ({ statusDescription }) => {
  const statusColumns = [
    { field: 'code', header: 'Code' },
    { field: 'description', header: 'Description' },
    { field: 'fee', header: 'Fee' , body: (rowData) => <div style={{ textAlign: 'center' }}>${rowData.fee}</div>},
    { field: 'isCompletedPayment', header: 'Is Completed Payment' },
    { field: 'patientName', header: 'Patient Name'},
    {
      field: 'teethNumber',
      header: 'Teeth Number',
      body: (rowData) => <div style={{ textAlign: 'center' }}>{rowData.teethNumber}</div>
    },
    { field: 'status', header: 'Status' },
  ];


  return (
    <DataTable value={statusDescription} style={{paddingTop:'10px'}}>
      {statusColumns.map(col => (
        <Column key={col.field} field={col.field} header={col.header} body={col.body} />
      ))}
    </DataTable>
  );
};

export default StatusDetailsTable;
