import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { Dropdown } from "primereact/dropdown";
import "./InsuranceClaim.scss";



const EditableStatusTemplate = ({ rowData, getDataFromServer,handleSave }) => {
    const [editMode, setEditMode] = useState(false);
    const [status, setStatus] = useState(rowData.item.status);
    const statusOptions = [
        { label: "Accepted", value: "Accepted" },
        { label: "Rejected", value: "Rejected" }
    ];
    const saveChanges = () => {
        handleSave(rowData, status); 
        setEditMode(false); 
      };
    

    return (
        <div className="insurance-claim-editable">
            {editMode ? (
                <div className="insurance-claim-table-dropdown" style={{ display: 'flex', alignItems: 'center'}}>
                    <Dropdown 
                        value={status} 
                        options={statusOptions} 
                        onChange={e => setStatus(e.value)} 
                        placeholder="Select a status"
                        style={{ width: '150px', marginRight: '5px' }}
                    />
                    <FontAwesomeIcon icon={faSave} onClick={saveChanges} style={{ marginLeft: '5px', cursor: 'pointer' }} />
                </div>
            ) : (
                <div>
                    {rowData.item.status || "Sent"}
                    <FontAwesomeIcon icon={faEdit} onClick={() => setEditMode(true)} style={{ marginLeft: '5px', cursor: 'pointer' }} />
                </div>
            )}
        </div>
    );
};

export default EditableStatusTemplate;
