import React, { PureComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';

import config from '../../../config/config';
import configImages from '../../../config/configImages';
//session expiry modal
import SessionExpiryModal from '../../Cruds/CommonModals/SessionexpiryModal'
import fetchMethodRequest from '../../../config/service';

const Topbar  = (props) => {
  const propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  const [settingsInfo, setSettingsInfo] = useState({});
  const [sessionExpiryModal, setsessionExpiryModal] = useState(false);
  const [hospitalData,setHospitalData] = useState({});
 
  useEffect(() => {
    let sessionexpired = localStorage.getItem('sessionexpired');
    if(localStorage.HospitalData){
      setHospitalData(JSON.parse(localStorage.getItem('HospitalData')));
    }
    
    if (sessionexpired == "true") {      
      setsessionExpiryModal(true);
    }    
    return () => {      
    };
  }, []);

 
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = props;
    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <Link className='topbarLogo ml-3' to="/users" >
              {config.displayProjectName ?
                <img src={configImages.loginImage} className='topbarProjectLogo'></img> : hospitalData.hospitalName ? hospitalData.hospitalName : config.appName}
            </Link>

          </div>
          <div className="topbar__right">
            <TopbarProfile />
          </div>
          {sessionExpiryModal ?
            <SessionExpiryModal
              SOpen={sessionExpiryModal}
            />
            : null}
        </div>
      </div>
    );
  }


export default Topbar;
