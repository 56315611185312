/* eslint indent: "off" */
import React from 'react';
import { Route, Routes, createBrowserRouter, Outlet } from 'react-router-dom';
import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';
import Register from '../Register/index';

// Login
import Landing from '../Landing/LogIn';
// import LoginCheck from '../Landing/loginCheck';

// Dashboard
import Home from '../Dashboard/index';

// Profile
import ChangePassword from '../Account/ChangePassword/index';
import ChangeRecoverPassword from '../Account/ChangeRecoverPassword/index';
import ForgotPassword from '../Account/ForgotPassword/index';
import LoginChangePassword from '../Account/LoginChangePassword/index';
import Profile from '../Account/Profile';
import ReleaseNotes from '../Account/ReleaseNotes'

import EmailTemplate from '../Cruds/EmailTemplates';
import Roles from '../Cruds/Roles';
import Activities from '../Cruds/Activities'
import EmailStatus from '../Cruds/EmailStatus/index';
// Tables
import Users from '../Cruds/Users/index';
import Clinics from '../Cruds/Clinics/index';
import Patients from '../Cruds/Patients/index';
import Templates from '../Cruds/Templates/index';
import Forms from '../Cruds/Forms/index';
import Schedules from '../Cruds/Schedules/index';
import Chairs from '../Cruds/Chairs/index';
import Appointments from '../Cruds/Appointments/index';
import Procedures from '../Cruds/Procedures/index';
import TxPlans from '../Cruds/TxPlans/index';
import Treatments from '../Cruds/Treatments/index';
import Prescriptions from '../Cruds/Prescriptions/index';
import Pharmacies from '../Cruds/Pharmacies/index';
import TreatmentPlans from '../Cruds/TreatmentPlans/index';
import Drugs from '../Cruds/Drugs/index';
import Reasons from '../Cruds/Reasons/index';
import ClockInClockOut from '../Cruds/ClockInClockOut/index';

import Insurance from '../Cruds/Insurance/index';
import FeeSchedules from '../Cruds/FeeShedules/index';
import Charts from '../Cruds/Charts/index';
import Referrals from '../Cruds/Referrals/index';
import Reports from '../Cruds/Reports/index.jsx';


import ScheduleCalendar from '../Form/Fields/ScheduleCalendar';
import AvailablePrompts from '../Cruds/AvailablePrompts/index';
import AutoNotes from '../Cruds/AutoNotes/index'
import Accounts from '../Cruds/Accounts/index.jsx';
import Imaging from '../Cruds/Imaging/index.jsx';
import OrthoForm from '../Cruds/OrthoForm/index.jsx';
import InsuranceClaim from '../Cruds/InsuranceClaim/index.jsx';

// import Employees from '../Cruds/Employees/index';

import Settings from '../Cruds/Settings/index'
import AdminSettings from '../Settings/index'
import Uploads from '../Cruds/Uploads/index';
// 404 page not found 
import ErrorNotFound from '../Account/404/index';
import config from '../../config/config';
import SampleForm from '../../Sample/SampleForm.jsx';
import Verification from '../Landing/LogIn/components/index.jsx';
import GoogleAuthenticator from '../Landing/GoogleLogin/index.jsx';
import VerifyGoogleAuthenticator from '../Landing/GoogleLogin/components/index.jsx';


const Tables = () => {
  let roles = localStorage.rolePermissions
    ? JSON.parse(localStorage.rolePermissions)
    : false;
  if (roles) {
    return (
      <>
        <Routes>
          <Route path='/sampleform'
            element={<SampleForm />}
          ></Route>
          <Route exact
            path="/users"
            element={
              roles['Users'] &&
                (roles['Users']?.type === "Edit" || roles['Users']?.type === "View")
                ? <Users />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_users"
            element={
              roles['Users'] &&
                (roles['Users']?.type === "Edit")
                ? <Users />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_users/:id"
            element={
              roles['Users'] &&
                (roles['Users']?.type === "Edit")
                ? <Users />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_users/:id"
            element={
              roles['Users'] &&
                (roles['Users']?.type === "Edit" || roles['Users']?.type === "View")
                ? <Users />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/clinics"
            element={
              roles['Clinics'] &&
                (roles['Clinics']?.type === "Edit" || roles['Clinics']?.type === "View")
                ? <Clinics />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_clinics"
            element={
              roles['Clinics'] &&
                (roles['Clinics']?.type === "Edit")
                ? <Clinics />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_clinics/:id"
            element={
              roles['Clinics'] &&
                (roles['Clinics']?.type === "Edit")
                ? <Clinics />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_clinics/:id"
            element={
              roles['Clinics'] &&
                (roles['Clinics']?.type === "Edit" || roles['Clinics']?.type === "View")
                ? <Clinics />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/patients"
            element={
              roles['Patients'] &&
                (roles['Patients']?.type === "Edit" || roles['Patients']?.type === "View")
                ? <Patients />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_patients"
            element={
              roles['Patients'] &&
                (roles['Patients']?.type === "Edit")
                ? <Patients />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_patients/:id"
            element={
              roles['Patients'] &&
                (roles['Patients']?.type === "Edit")
                ? <Patients />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_patients/:id"
            element={
              roles['Patients'] &&
                (roles['Patients']?.type === "Edit" || roles['Patients']?.type === "View")
                ? <Patients />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/reasons"
            element={
              roles['Services'] &&
                (roles['Services']?.type === "Edit" || roles['Services']?.type === "View")
                ? <Reasons />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_reasons"
            element={
              roles['Services'] &&
                (roles['Services']?.type === "Edit")
                ? <Reasons />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_reasons/:id"
            element={
              roles['Services'] &&
                (roles['Services']?.type === "Edit")
                ? <Reasons />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_clockify/:id"
            element={
              roles['Clockify'] &&
                (roles['Clockify']?.type === "Edit" || roles['Clockify']?.type === "View")
                ? <ClockInClockOut />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/clockify"
            element={
              roles['Clockify'] &&
                (roles['Clockify']?.type === "Edit" || roles['Clockify']?.type === "View")
                ? <ClockInClockOut />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_clockify"
            element={
              roles['Clockify'] &&
                (roles['Clockify']?.type === "Edit")
                ? <ClockInClockOut />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_clockify/:id"
            element={
              roles['Clockify'] &&
                (roles['Clockify']?.type === "Edit")
                ? <ClockInClockOut />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/feeSchedules"
            element={
              roles['Fee Schedules'] &&
                (roles['Fee Schedules']?.type === "Edit")
                ? <FeeSchedules />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/create_feeSchedules"
            element={
              roles['Fee Schedules'] &&
                (roles['Fee Schedules']?.type === "Edit")
                ? <FeeSchedules />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_feeSchedules/:id"
            element={
              roles['Fee Schedules'] &&
                (roles['Fee Schedules']?.type === "Edit")
                ? <FeeSchedules />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_patients/:id"
            element={
              roles['Patients'] &&
                (roles['Patients']?.type === "Edit" || roles['Patients']?.type === "View")
                ? <Patients />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/forms"
            element={
              roles['Forms'] &&
                (roles['Forms']?.type === "Edit" || roles['Forms']?.type === "View")
                ? <Forms />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_forms"
            element={
              roles['Forms'] &&
                (roles['Forms']?.type === "Edit")
                ? <Forms />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_forms/:id"
            element={
              roles['Forms'] &&
                (roles['Forms']?.type === "Edit")
                ? <Forms />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_forms/:id"
            element={
              roles['Forms'] &&
                (roles['Forms']?.type === "Edit" || roles['Forms']?.type === "View")
                ? <Forms />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/appointments"
            element={
              roles['Appointments'] &&
                (roles['Appointments']?.type === "Edit" || roles['Appointments']?.type === "View")
                ? <Appointments />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_appointments"
            element={
              roles['Appointments'] &&
                (roles['Appointments']?.type === "Edit")
                ? <Appointments />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_appointments/:id"
            element={
              roles['Appointments'] &&
                (roles['Appointments']?.type === "Edit")
                ? <Appointments />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_appointments/:id"
            element={
              roles['Appointments'] &&
                (roles['Appointments']?.type === "Edit" || roles['Appointments']?.type === "View")
                ? <Appointments />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/schedules"
            element={
              roles['Schedules'] &&
                (roles['Schedules']?.type === "Edit" || roles['Schedules']?.type === "View")
                ? <ScheduleCalendar />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_schedules"
            element={
              roles['Schedules'] &&
                (roles['Schedules']?.type === "Edit")
                ? <ScheduleCalendar />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_schedules/:id"
            element={
              roles['Schedules'] &&
                (roles['Schedules']?.type === "Edit")
                ? <ScheduleCalendar />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_schedules/:id"
            element={
              roles['Schedules'] &&
                (roles['Schedules']?.type === "Edit" || roles['Schedules']?.type === "View")
                ? <ScheduleCalendar />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/treatments"
            element={
              roles['Treatments'] &&
                (roles['Treatments']?.type === "Edit" || roles['Treatments']?.type === "View")
                ? <Treatments />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_treatments"
            element={
              roles['Treatments'] &&
                (roles['Treatments']?.type === "Edit")
                ? <Treatments />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_treatments/:id"
            element={
              roles['Treatments'] &&
                (roles['Treatments']?.type === "Edit")
                ? <Treatments />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_treatments/:id"
            element={
              roles['Treatments'] &&
                (roles['Treatments']?.type === "Edit" || roles['Treatments']?.type === "View")
                ? <Treatments />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/adminSettings"
            element={
              config.displaySettings
                ? <AdminSettings />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/uploads"
            element={
              roles["Upload Histories"] &&
                (roles["Upload Histories"]?.type === "Edit" || roles["Upload Histories"]?.type === "View")
                ? <Uploads />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/activities"
            element={
              roles["Activities"] &&
                (roles["Activities"]?.type === "Edit" || roles["Activities"]?.type === "View")
                ? <Activities />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/activities/:id"
            element={
              roles["Activities"] &&
                (roles["Activities"]?.type === "Edit" || roles["Activities"]?.type === "View")
                ? <Activities />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_activities/:id"
            element={
              roles["Activities"] &&
                (roles["Activities"]?.type === "Edit" || roles["Activities"]?.type === "View")
                ? <Activities />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/roles"
            element={
              roles["Roles"] &&
                (roles["Roles"]?.type === "Edit" || roles["Roles"]?.type === "View")
                ? <Roles />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/create_roles"
            element={
              roles["Roles"] &&
                (roles["Roles"]?.type === "Edit")
                ? <Roles />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/edit_roles/:id"
            element={
              roles["Roles"] &&
                (roles["Roles"]?.type === "Edit")
                ? <Roles />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/view_roles/:id"
            element={
              roles["Roles"] &&
                (roles["Roles"]?.type === "Edit" || roles["Roles"]?.type === "View")
                ? <Roles />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/emailStatuses"
            element={
              roles["Email Statuses"] &&
                (roles["Email Statuses"]?.type === "Edit" || roles["Email Statuses"]?.type === "View")
                ? <EmailStatus />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/templates"
            element={
              roles['Templates'] &&
                (roles['Templates']?.type === "Edit" || roles['Templates']?.type === "View")
                ? <Templates />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_templates"
            element={
              roles['Templates'] &&
                (roles['Templates']?.type === "Edit")
                ? <Templates />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_templates/:id"
            element={
              roles['Templates'] &&
                (roles['Templates']?.type === "Edit")
                ? <Templates />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_templates/:id"
            element={
              roles['Templates'] &&
                (roles['Templates']?.type === "Edit" || roles['Templates']?.type === "View")
                ? <Templates />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/create_chairs"
            element={
              roles['Chairs'] &&
                (roles['Chairs']?.type === "Edit")
                ? <Chairs />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_chairs/:id"
            element={
              roles['Chairs'] &&
                (roles['Chairs']?.type === "Edit")
                ? <Chairs />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_chairs/:id"
            element={
              roles['Chairs'] &&
                (roles['Chairs']?.type === "Edit" || roles['Chairs']?.type === "View")
                ? <Chairs />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/procedures"
            element={
              roles['Procedures'] &&
                (roles['Procedures']?.type === "Edit" || roles['Procedures']?.type === "View")
                ? <Procedures />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_procedures"
            element={
              roles['Procedures'] &&
                (roles['Procedures']?.type === "Edit")
                ? <Procedures />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_procedures/:id"
            element={
              roles['Procedures'] &&
                (roles['Procedures']?.type === "Edit")
                ? <Procedures />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_procedures/:id"
            element={
              roles['Procedures'] &&
                (roles['Procedures']?.type === "Edit" || roles['Procedures']?.type === "View")
                ? <Procedures />
                : <ErrorNotFound />
            }
          />



          <Route exact
            path="/insurance"
            element={
              roles['Insurance'] &&
                (roles['Insurance']?.type === "Edit" || roles['Insurance']?.type === "View")
                ? <Insurance />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_insurance"
            element={
              roles['Insurance'] &&
                (roles['Insurance']?.type === "Edit")
                ? <Insurance />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_insurance/:id"
            element={
              roles['Insurance'] &&
                (roles['Insurance']?.type === "Edit")
                ? <Insurance />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_insurance/:id"
            element={
              roles['Insurance'] &&
                (roles['Insurance'].type === "Edit" || roles['Insurance']?.type === "View")
                ? <Insurance />
                : <ErrorNotFound />
            }
          />


          <Route exact
            path="/drugs"
            element={
              roles['Drugs'] &&
                (roles['Drugs']?.type === "Edit" || roles['Drugs']?.type === "View")
                ? <Drugs />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_drugs"
            element={
              roles['Drugs'] &&
                (roles['Drugs']?.type === "Edit")
                ? <Drugs />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_drugs/:id"
            element={
              roles['Drugs'] &&
                (roles['Drugs']?.type === "Edit")
                ? <Drugs />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_drugs/:id"
            element={
              roles['Drugs'] &&
                (roles['Drugs']?.type === "Edit" || roles['Drugs']?.type === "View")
                ? <Drugs />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/treatmentPlan"
            element={
              roles['Treatment Plan'] &&
                (roles['Treatment Plan']?.type === "Edit" || roles['Treatment Plan']?.type === "View")
                ? <TreatmentPlans />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_treatmentPlan"
            element={
              roles['Treatment Plan'] &&
                (roles['Treatment Plan']?.type === "Edit")
                ? <TreatmentPlans />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_treatmentPlan/:id"
            element={
              roles['Treatment Plan'] &&
                (roles['Treatment Plan']?.type === "Edit")
                ? <TreatmentPlans />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_treatmentPlan/:id"
            element={
              roles['Treatment Plan'] &&
                (roles['Treatment Plan']?.type === "Edit" || roles['Treatment Plan']?.type === "View")
                ? <TreatmentPlans />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/prescriptions"
            element={
              roles['Prescriptions'] &&
                (roles['Prescriptions']?.type === "Edit" || roles['Prescriptions']?.type === "View")
                ? <Prescriptions />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_prescriptions"
            element={
              roles['Prescriptions'] &&
                (roles['Prescriptions']?.type === "Edit")
                ? <Prescriptions />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_prescriptions/:id"
            element={
              roles['Prescriptions'] &&
                (roles['Prescriptions']?.type === "Edit")
                ? <Prescriptions />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_prescriptions/:id"
            element={
              roles['Prescriptions'] &&
                (roles['Prescriptions']?.type === "Edit" || roles['Prescriptions']?.type === "View")
                ? <Prescriptions />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/txPlans"
            element={
              roles['Tx Plan'] &&
                (roles['Tx Plan']?.type === "Edit" || roles['Tx Plan']?.type === "View")
                ? <TxPlans />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_txPlans"
            element={
              roles['Tx Plan'] &&
                (roles['Tx Plan']?.type === "Edit")
                ? <TxPlans />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_txPlans/:id"
            element={
              roles['Tx Plan'] &&
                (roles['Tx Plan']?.type === "Edit")
                ? <TxPlans />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_txPlans/:id"
            element={
              roles['Tx Plan'] &&
                (roles['Tx Plan']?.type === "Edit" || roles['Tx Plan']?.type === "View")
                ? <TxPlans />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/pharmacies"
            element={
              roles['Pharmacies'] &&
                (roles['Pharmacies']?.type === "Edit" || roles['Pharmacies']?.type === "View")
                ? <Pharmacies />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_pharmacies"
            element={
              roles['Pharmacies'] &&
                (roles['Pharmacies']?.type === "Edit")
                ? <Pharmacies />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_pharmacies/:id"
            element={
              roles['Pharmacies'] &&
                (roles['Pharmacies']?.type === "Edit")
                ? <Pharmacies />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_pharmacies/:id"
            element={
              roles['Pharmacies'] &&
                (roles['Pharmacies']?.type === "Edit" || roles['Pharmacies']?.type === "View")
                ? <Pharmacies />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/orthoForm"
            element={
              roles['Ortho Form'] &&
                (roles['Ortho Form']?.type === "Edit" || roles['Ortho Form']?.type === "View")
                ? <OrthoForm />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_orthoForm"
            element={
              roles['Ortho Form'] &&
                (roles['Ortho Form']?.type === "Edit")
                ? <OrthoForm />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_imageUploads/:id"
            element={
              roles['Ortho Form'] &&
                (roles['Ortho Form']?.type === "Edit")
                ? <OrthoForm />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_imageUploads/:id"
            element={
              roles['Ortho Form'] &&
                (roles['Ortho Form']?.type === "Edit" || roles['Ortho Form']?.type === "View")
                ? <OrthoForm />
                : <ErrorNotFound />
            }
          />
          
          <Route exact
            path="/insuranceClaim"
            element={
              roles['Insurance Claim'] &&
                (roles['Insurance Claim']?.type === "Edit" || roles['Insurance Claim']?.type === "View")
                ? <InsuranceClaim />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_insuranceClaim"
            element={
              roles['Insurance Claim'] &&
                (roles['Insurance Claim']?.type === "Edit")
                ? <InsuranceClaim />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_insuranceClaim/:id"
            element={
              roles['Insurance Claim'] &&
                (roles['Insurance Claim']?.type === "Edit")
                ? <InsuranceClaim />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_insuranceClaim/:id"
            element={
              roles['Insurance Claim'] &&
                (roles['Insurance Claim']?.type === "Edit" || roles['Insurance Claim']?.type === "View")
                ? <InsuranceClaim />
                : <ErrorNotFound />
            }
          />


          <Route exact
            path="/reasons"
            element={
              roles['Reasons'] &&
                (roles['Reasons']?.type === "Edit" || roles['Reasons']?.type === "View")
                ? <Reasons />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_reasons"
            element={
              roles['Reasons'] &&
                (roles['Reasons']?.type === "Edit")
                ? <Reasons />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_reasons/:id"
            element={
              roles['Reasons'] &&
                (roles['Reasons']?.type === "Edit")
                ? <Reasons />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_reasons/:id"
            element={
              roles['Reasons'] &&
                (roles['Reasons']?.type === "Edit" || roles['Reasons']?.type === "View")
                ? <Reasons />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/accounts"
            element={
              roles['Accounts'] &&
                (roles['Accounts']?.type === "Edit" || roles['Accounts']?.type === "View")
                ? <Accounts />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_accounts"
            element={
              roles['Accounts'] &&
                (roles['Accounts']?.type === "Edit")
                ? <Accounts />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_accounts/:id"
            element={
              roles['Accounts'] &&
                (roles['Accounts']?.type === "Edit")
                ? <Accounts />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_accounts/:id"
            element={
              roles['Accounts'] &&
                (roles['Accounts']?.type === "Edit" || roles['Accounts']?.type === "View")
                ? <Accounts />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/imaging"
            element={
              roles['Imaging'] &&
                (roles['Imaging']?.type === "Edit" || roles['Imaging']?.type === "View")
                ? <Imaging />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_imaging"
            element={
              roles['Imaging'] &&
                (roles['Imaging']?.type === "Edit")
                ? <Imaging />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_imaging/:id"
            element={
              roles['Imaging'] &&
                (roles['Imaging']?.type === "Edit")
                ? <Imaging />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_imaging/:id"
            element={
              roles['Imaging'] &&
                (roles['Imaging']?.type === "Edit" || roles['Imaging']?.type === "View")
                ? <Imaging />
                : <ErrorNotFound />
            }
          />




          <Route
            path="/autoNotes"
            element={
              roles['Auto Notes'] &&
                (roles['Auto Notes']?.type === "Edit" || roles['Auto Notes']?.type === "View")
                ? <AutoNotes />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/create_autoNotes"
            element={
              roles['Auto Notes'] &&
                (roles['Auto Notes']?.type === "Edit")
                ? <AutoNotes />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/edit_autoNotes/:id"
            element={
              roles['Auto Notes'] &&
                (roles['Auto Notes']?.type === "Edit")
                ? <AutoNotes />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/view_autoNotes/:id"
            element={
              roles['Auto Notes'] &&
                (roles['Auto Notes']?.type === "Edit" || roles['Auto Notes']?.type === "View")
                ? <AutoNotes />
                : <ErrorNotFound />
            }
          />



          <Route
            path="/charts"
            element={
              roles['Charts'] &&
                (roles['Charts']?.type === "Edit" || roles['Charts']?.type === "View")
                ? <Charts />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/referrals"
            element={
              roles['Referrals'] &&
                (roles['Referrals']?.type === "Edit" || roles['Referrals']?.type === "View")
                ? <Referrals />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_referrals"
            element={
              roles['Referrals'] &&
                (roles['Referrals']?.type === "Edit")
                ? <Referrals />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_referrals/:id"
            element={
              roles['Referrals'] &&
                (roles['Referrals']?.type === "Edit")
                ? <Referrals />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_referrals/:id"
            element={
              roles['Referrals'] &&
                (roles['Referrals']?.type === "Edit" || roles['Referrals']?.type === "View")
                ? <Referrals />
                : <ErrorNotFound />
            }
          />



          <Route exact
            path="/availablePrompts"
            element={
              roles['Available Prompts'] &&
                (roles['Available Prompts']?.type === "Edit" || roles['Available Prompts']?.type === "View")
                ? <AvailablePrompts />
                : <ErrorNotFound />
            } />
          <Route
            path="/create_availablePrompts"
            element={
              roles['Available Prompts'] &&
                (roles['Available Prompts']?.type === "Edit")
                ? <AvailablePrompts />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/edit_availablePrompts/:id"
            element={
              roles['Available Prompts'] &&
                (roles['Available Prompts']?.type === "Edit")
                ? <AvailablePrompts />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/view_availablePrompts/:id"
            element={
              roles['Available Prompts'] &&
                (roles['Available Prompts']?.type === "Edit" || roles['Available Prompts']?.type === "View")
                ? <AvailablePrompts />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/reports"
            element={
              roles['Reports'] &&
                (roles['Reports']?.type === "Edit" || roles['Reports']?.type === "View")
                ? <Reports />
                : <ErrorNotFound />
            } />
          {/* <Route path="/uploads" element={Uploads} />
    <Route path="/activities" element={Activities} />
    <Route path="/roles" element={Roles} />
    <Route path="/templates" element={EmailTemplate} /> */}
        </Routes >
        <Outlet />
      </>
    )
  }
}

const Account = () => (
  <Routes>
    <Route path="/changepassword" element={<ChangePassword />} />
    <Route path="/profile" element={<Profile />} />

  </Routes>

);

const WrappedRoutes = () => {
  let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
  if (loginCredentials) {
    return <div>
      <h1>Hello</h1>
      <Layout />
      <div className="container__wrap">
        <Routes>
          {/* <Route path="/" element={<Account />} /> */}
          <Route path="/*" index={true} element={<Tables />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/dashBoard" element={<Home />} />
          <Route path="/releasenotes" element={<ReleaseNotes />} />
        </Routes>

      </div>
      <Outlet />
    </div>
  }
  else {
    return <ErrorNotFound />
  }
}

const Router = () => (
  <MainWrapper>
    <main>
      <Routes>
        <Route path="/changeRecoverPassword/:enEmail" element={<ChangeRecoverPassword />} />
        <Route path="/loginChangePassword" element={<LoginChangePassword />} />
        <Route exact path="/" element={<Landing />} />
        <Route path="/log_in" element={<Landing />} />
        <Route path="/logout" element={<Landing />} />
        <Route path="/two_step_verification" element={<Verification />} />
        <Route path="/authenticator" element={<GoogleAuthenticator />} />
        <Route path="/verify_authenticator" element={<VerifyGoogleAuthenticator />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />

        <Route path="/*" element={<WrappedRoutes />} />

        <Route element={<ErrorNotFound />} />


      </Routes>
      <Outlet />
    </main>
  </MainWrapper>
);

export default Router;
