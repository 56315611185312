import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const ConfirmationDialog = (props) => {
    let displayText = props.type === "Insurance" ? "Are you a subscriber?" : "Are you sure you want to";

    return (
        <div>
            <Dialog 
                header="Confirmation"
                visible={props.openConfirmationModal}
                onHide={props.closeConfirmationModal}
                style={{ width: '30vw' }}
                className="p-dialog-centered"
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '10px' }}>
                    <div style={{ marginBottom: 20, textAlign: 'center' }}>{displayText} {props.text}?</div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button color="primary" outline onClick={props.closeConfirmationModal} className='deleteModalBtn marginRight' style={{ marginRight: 10 }}>No</Button>
                        <Button color="primary" outline onClick={props.confirm} className='deleteModalBtn'>Yes</Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default ConfirmationDialog;
