import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import TreatmentPlanInputField from "../../Prescriptions/components/TreatmentPlanInputField";
import DragAndDropTreatment from "../../../Form/Fields/DragAndDropTreatment";
import TreatmentPlan from "./TreatmentPlan";

// config file

const TreatmentPlans = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);
  const [heading,setHeading] = useState();
  const [patientId, setPatientId] = useState();
  const [treatmentPlanHeading, setTreatmentPlanHeading] = useState(null);
  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Treatment Plans");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };
  // The Procedure Steps table Fields to Display 
  const procedureStepsTableFields = [
    {field : 'code' ,header : 'Code' ,filter:true},
    {field : 'description' ,filter:true ,header : 'Description' ,},
    {field : 'displayFee' ,header : 'Fee' ,},
  ]
  const test1 = (customProps)=>{
    // customProps.setValue();
    let currentHeadingData;
    if (customProps.getDataByKey) {
      let newHeadingData = customProps.getDataByKey('treatmentPlanHeading');
      if (currentHeadingData !== newHeadingData) {
        currentHeadingData = newHeadingData;
        customProps.updateValue('procedureSteps', newHeadingData.procedureSteps);
      }
    }
    setTreatmentPlanHeading(customProps.watch('treatmentPlanHeading'));

    return <DragAndDropTreatment
      item={procedureStepsTableFields}
      {...customProps}
      data={customProps.watch('treatmentPlanHeading') ? customProps.watch('treatmentPlanHeading') : ''}
      patient={patientId}
    />
    // if(heading?.procedureSteps?.length>0){
    //   return heading.procedureSteps.map((obj)=><div>{obj.abbreviation?obj.abbreviation:'jiojik'}</div>)
    // }
    //   return 
  }
  const getTableFields = () => {

    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    const tableFields = [
      {
        header:'Sno',
        field:'sno'
      },{
        header:'Code',
        field:'code'
      },{
        header:'Description',
        field:'description',
        bodyStyle: {width: '400px', wordBreak: 'break-all', whiteSpace: 'pre-line', maxHeight: '100px',overflowY: 'auto', }
      },{
        header:'Fee',
        field:'displayFee'
      },{
        header:'Category',
        field:'category'
      },{
        header:'Abbreviation',
        field:'abbreviation'
      }
    ];
    let data = [
      {
        // header:'Procedure Steps',
        type:'expander',
        textAlign: "center",
        width: 20,
        field: "expander",
        label: "expander",
        // header: "Procedure Steps",
        expandField:'procedureSteps',
        tableFields:tableFields,
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        textAlign: "center",
        width: 40,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 47,
        "field": "Sno",
        "header": "Sno",
        "filter": false,
        "sortable": false,
        "placeholder": "Search",
        displayInSettings: true,
      },
      // {
      //   name: "hospitalName",
      //   type: "relateAutoComplete",
      //   placeholder: "Hospital Name",
      //   label: "Hospital Name",
      //   width: "150px",
      //   addFormOrder: 6,
      //   editFormOrder: 6,
      //   header: "Hospital Name",
      //   derivedValue: "hospitalName=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   actions: [],
      //   actionsNumber: [],
      //   id: "hospitalName",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: false,
      //   globalSearchField: "false",
      //   controllerId: 1001,
      //   searchField: "hospitalName",
      //   populteFields: [],
      //   displayFields: [],
      //   controllerName: "hospitals",
      //   searchApi: "hospitals",
      //   isMultiple: false,
      //   textAlign: "Center",
      //   show: true,
      //   filter: true,
      //   field: "hospitalId",
      //   fieldType: "relateAutoComplete",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      // },
      // {
      //   name: "hospitalName",
      //   type: "relateAutoComplete",
      //   placeholder: "Hospital Name",
      //   label: "Hospital Name",
      //   width: "150px",
      //   addFormOrder: 6,
      //   editFormOrder: 6,
      //   header: "Hospital Name",
      //   derivedValue: "hospitalName=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   actions: [],
      //   actionsNumber: [],
      //   id: "hospitalName",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: false,
      //   globalSearchField: "false",
      //   controllerId: 1001,
      //   searchField: "hospitalID",
      //   populteFields: [],
      //   displayFields: [],
      //   controllerName: "hospitals",
      //   searchApi: "hospitals",
      //   isMultiple: false,
      //   textAlign: "Center",
      //   show: true,
      //   filter: true,
      //   field: "hospitalsId",
      //   fieldType: "relateAutoComplete",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      // },
      {
        name: "patientId",
        type: "uniqueField",
        placeholder: "PatientID",
        label: "PatientID",
        width: "120px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "PatientID",
        derivedValue: "patientId=undefined",
        capitalizeTableText: false,
        sortable: false,
        id: "patientId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: 1008,
        textAlign: "Center",
        show: true,
        field: "patientId",
        filter: true,
        searchField: "patientId",
        fieldType: "relateAutoComplete",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "heading",
        type: "uniqueField",
        placeholder: "Heading",
        label: "Heading",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Heading",
        derivedValue: "heading=undefined",
        capitalizeTableText: false,
        sortable: false,
        id: "heading",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        hasDependency: "true",
        fieldForKey: "patientId",
        hasDependencyField: "patientId",
        show: true,
        field: "heading",
        filter:true,
        showOrHideFields: [],
        fieldName: "heading",
        mobile: true,
        displayInSettings: true,
      },{
        name: "note",
        type: "textarea",
        placeholder: "Note",
        label: "Note",
        width: "100px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Note",
        derivedValue: "note=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "note",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "note",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        // "actions": [],
        // "actionsNumber": [],
        id: "created",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.monthDateFormat,
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true
      },
      {
        "name": "updated",
        "type": "date",
        "placeholder": "Updated",
        "label": "Updated",
        "width": 90,
        "header": "Updated",
        "derivedValue": "updated=undefined",
        // "actions": [],
        // "actionsNumber": [],
        "id": "updated",
        "displayinaddForm": "false",
        "displayineditForm": "false",
        "displayinlist": "true",
        "globalSearchField": "false",
        "controllerId": null,
        "fieldType": "Date",
        "dateFormat": config.monthDateFormat,
        "textAlign": "Center",
        "disabled": true,
        "show": true,
        "field": "updated",
        "mobile": true,
        "displayInSettings": true
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 60,
        "fieldType": "Actions",
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "sortable": false,
        displayInSettings: true,
      }
    ];
    return data;
  };

  const treatementCustomType = (customProps) => {
    return <TreatmentPlanInputField {...customProps} heading={heading} />
  }


  const treatementValidation = (yup) => {
    return yup.array().required();
  }
  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];



    return [
      // {
      //   name: "hospitalsId",
      //   type: "relateAutoComplete",
      //   placeholder: "Practice ID",
      //   label: "Practice ID",
      //   width: "150px",
      //   addFormOrder: 1,
      //   editFormOrder: 1,
      //   header: "Practice ID",
      //   derivedValue: "hospitalsId=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   actions: [],
      //   actionsNumber: [],
      //   id: "hospitalsId",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: false,
      //   globalSearchField: "false",
      //   controllerId: 1001,
      //   searchField: "hospitalID",
      //   fieldType: "relateAutoComplete",
      //   populateField: "hospitalName",
      //   populateValue: "hospitalID",
      //   controllerName: "hospitals",
      //   searchApi: "hospitals",
      //   isMultiple: false,
      //   textAlign: "Center",
      //   show: true,
      //   field: "hospitalsId",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      // }
      {
        name: "patientId",
        type: "relateAutoComplete",
        placeholder: "Patient Name",
        label: "Patient Name",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Patient Name",
        derivedValue: "patientId=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "patientId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        addFormOrder: 1,
        editFormOrder: 1,
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        onChange :(val,setValue)=>{
          if (setValue) {
            setValue('note', '');
            setValue('treatmentPlanHeading',null);
          }
          setPatientId(val);
        },
        searchField: "fullName",
        fieldType: "relateAutoComplete",
        hasDependency: "true",
        fieldForKey: "clinicId",
        hasDependencyField: "clinicId",
        populateValue: "firstName",
        populateField: "patientId",
        // populateValue: "firstName",
        // populateField: "patientId",
        populateValueLabel:'ID',
        controllerName: "patients",
        searchApi: "patients",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "patientId",
        formType:'add',
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        clinicFilter: true
      }
      ,
      //  {
      //           name: "heading",
      //     type: "customType",
      //     customType: txCustomType,
      //     placeholder: "heading",
      //     label: "Heading",
      //     width: "130px",
      //     disableLabel: true,
      //     addFormOrder: 14,
      //     editFormOrder: 14,
      //     derivedValue: "heading=undefined",
      //     capitalizeTableText: false,
      //     sortable: false,
      //     isClickable: false,
      //     filter: false,
      //     actions: [],
      //     actionsNumber: [],
      //     id: "heading",
      //     searchApi: apiCalls.txPlan,
      //     searchField: 'heading',
      //     displayinaddForm: "true",
      //     displayineditForm: "true",
      //     displayinlist: "true",
      //     isFieldRequired: "false",
      //     required: false,
      //     displayOptionsInActions: false,
      //     globalSearchField: "false",
      //     controllerId: null,
      //     show: true,
      //     showOrHideFields: [],
      //     mobile: true,
      //     displayInSettings: true,
      //     isAddFormHidden: false,
      //     isEditFormHidden: false,
      //   },
      {
        name: "treatmentPlanHeading",
        type: "customType",
        customType: treatementCustomType,
        placeholder: "Treatment Plans",
        label: "Treatment",
        width: "130px",
        disableLabel: true,
        addFormOrder: 14,
        editFormOrder: 14,
        derivedValue: "heading=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "treatmentPlanHeading",
        searchApi: apiCalls.treatmentPlan,
        searchField: 'heading',
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        onChange: (val, setValue) => {  
          if (setValue) {
            setValue('note', val.note);
          }
          setHeading(val);
        },
        hasDependency: "true",
        fieldForKey: "patientId",
        hasDependencyField: "patientId",
        required: false,
        addFormOrder: 2,
        editFormOrder: 2,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      // {
      //   name:'procedureSteps',
      //   type:'tableItemsSelector',
      //   label:'Procedures',
      //   tableFields:procedureStepsTableFields,//TableFields to display
      //   searchApi: apiCalls.procedures,//Api call for the Procedures
      //   show: true,
      //   modal: "TreatmentPlanDatatable"
      // },
      {
        name: "heading",
        type: "customType",
        customType: test1,
        placeholder: "Heading",
        tableFields:procedureStepsTableFields,//TableFields to display
        searchApi: apiCalls.procedures,//Api call for the Procedures
        show: true,
        modal: "TreatmentPlanDatatable",
        disableLabel: true,
        addFormOrder: 14,
        editFormOrder: 14,
        derivedValue: "heading=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "heading",
        columnFields:[],
        searchApi: apiCalls.treatmentPlan,
        searchField: 'heading',
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        addFormOrder: 4,
        editFormOrder: 4,
        globalSearchField: "false",
        controllerId: null,
        customWidthClass:'col-12',
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customValidateName:'Treatment Plans'
      },

    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "name",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "Questions",
        type: "textarea",
        placeholder: "Questions",
        label: "Questions",
        width: "200px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Questions",
        derivedValue: "Questions=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Questions",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Questions",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();
  };

  const saveDataToServer = async (body, reset, setIsLoading, customFieldValidation, setValue) => {
    if (body && customFieldValidation.save) {
      let method, apiUrl;
      method = "PUT";
      apiUrl = `${apiCalls.treatmentPlan}/${body.treatmentPlanHeading._id}`;
      body.teethNumberValidation=false;
      fetchMethodRequest(method, apiUrl, {...body})
        .then(async (response) => {
          // let sessionexpired = await localStorage.getItem('sessionexpired')
          // if (sessionexpired === "true") {
          //   await this.setState({ sessionExpiryModal: true })
          // }
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
            setValue('patientId',body.patientId);
            setValue('treatmentPlanHeading', response.details);
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
          // this.setState({
          //   isLoading: false
          // });
          setIsLoading(false);
        })
        .catch((err) => {
          return err;
        });
    }
    return true;
  };


  return (
    <TreatmentPlan />

    // <span>
    //   {
    //     <DataTables
    //       {...props}
    //       getTableFields={getTableFields}
    //       formFields={getFormFields}
    //       addRequired={rolePermission && rolePermission == "Edit" ? true : false}
    //       editRequired={rolePermission && rolePermission == "Edit" ? true : false}
    //       deleteRequired={rolePermission && rolePermission == "Edit" ? true : false}
    //       viewRequired={rolePermission && rolePermission == "Edit" ? true : false}
    //       exportRequired={rolePermission && rolePermission == "Edit" ? true : false}
    //       sample={true}
    //       // globalSearch={'Display Name/Email'}
    //       // type='Questions'
    //       // apiUrl={apiCalls.Questions}
    //       // exportRequried={true}
    //       printRequried={true}
    //       actionsTypes={[
    //         {
    //           name: "Delete",
    //           options: [
    //             {
    //               label: "Delete",
    //               value: "Delete",
    //               show:
    //                 rolePermission && rolePermission == "Edit" ? true : false,
    //               multiple: true,
    //             },
    //           ],
    //         },
    //       ]}
    //       settingsRequired={true}
    //       filterRequired={false}
    //       gridRequried={true}
    //       exportToCsv={true}
    //       dateSearchRequired={false}
    //       searchInDateRangeField={""}
    //       setData={setData}
    //       editSelectedRecord={editSelectedRecord}
    //       addSelectedRecord={addSelectedRecord}
    //       viewSelectedRecord={viewSelectedRecord}
    //       // sampleFilePath={filePath.Treatments}
    //       globalSearch={"TX Plan/Questions"}
    //       type="Treatment Plans"
    //       routeTo={apiCalls.treatmentPlan}
    //       displayViewOfForm="screen"
    //       apiResponseKey={apiCalls.treatmentPlan}
    //       treatmentPlanHeading={treatmentPlanHeading}
    //       apiUrl={apiCalls.treatmentPlan}
    //       selectedId={params.id}
    //       getFamilyRelationShip={saveDataToServer}
    //       customHeader="Treatment Plans"
    //     />
    //   }
    // </span>
  );
};

export default TreatmentPlans;
